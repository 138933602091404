import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import BillEdit from '../../expenseAndBills/bills/bill.edit';
import InvoiceEdit from '../../salesAndInvoices/invoice.edit';
import { getFullTxData, updateTx } from './api';
import TxSeleton from '../../../styled/skeletons/Tx.Seleton';
import CreditNoteEdit from '../../CreditDebitNote/Credit.Note.Edit';
import DebitNoteEdit from '../../CreditDebitNote/Debit.Note.Edit';
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%"
  }
}));

export default function TxEdit(props) {
  const { walletId, txId } = useParams();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const { user } = useSelector((state) => state.auth);

  const [tx, setTx] = useState(null);
  const [discounts, setDiscounts] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [chartAccounts, setChartAccounts] = useState([]);
  const [bankChartAccounts, setBankChartAccounts] = useState([]);
  const [offerings, setOfferings] = useState([]);
  const [terms, setTerms] = useState([]);
  const [txNosArr, setTxNosArr] = useState([]);
  const [maxNo, setMaxNo] = useState()
  const [txLoading, setTxLoading] = useState(false)
  const [incomeChartAccounts, setIncomeChartAccounts] = useState([]);
  const [expenseChartAccounts, setExpenseChartAccounts] = useState([]);
  const [needToMakeDisable, setNeedToMakeDisable] = useState(false);
  const [activeStage, setActiveStage] = useState(null)
  const [needToMakeStatusDisable, setNeedToMakeStatusDisable] = useState(true)

  useEffect(() => {
    const curUrl = window.location.href
    if ((curUrl.includes("debit-note") || curUrl.includes("invoice")) && curUrl.includes("edit")) {
      setTxLoading(true)
      getFullTxData({
        walletId,
        txId,
        type: "Invoice",
        own: true,
        isView: false
      })
        .then((data) => {
          const addObject = {
            _id: 'New',
            name: "+ Add New",
            numDays: "0"
          };

          // to get tax and discounts
          if (data?.resDiscountAndTxs && data?.resDiscountAndTxs.length > 0) {
            const discountOrTaxs = data?.resDiscountAndTxs;
            let discounts = []
            let taxes = []

            discountOrTaxs.length > 0 && discountOrTaxs.map((discountOrTax) => {
              if (discountOrTax.model === "Discount") {
                discounts.push(discountOrTax)
              } else if (discountOrTax.model === "Tax") {
                taxes.push(discountOrTax)
              }
            })

            const modifiedDiscounts = [addObject, ...discounts];
            setDiscounts(modifiedDiscounts)
            const modifiedtaxes = [addObject, ...taxes];
            setTaxes(modifiedtaxes)
          }

          // to get products and services
          const offeringRelations = data?.resProductsAndServices || []
          let newOfferingsArr = [addObject, ...offeringRelations]
          setOfferings(newOfferingsArr);

          // to get terms for late fee and due date
          const getTerms = data?.resTerms || []
          const newFiltered = [addObject, ...getTerms];
          setTerms(newFiltered);

          // to get tx no array
          if (data?.resTxsForTxNoEdit && data.resTxsForTxNoEdit.length > 0) {
            let newArr = []
            let maxNoff = 1
            data.resTxsForTxNoEdit.map((txData) => {
              if (txData?.txNo && txData?.txNo !== data?.resTxData?.txNo) {
                newArr.push(txData.txNo)
              }
              if (txData?.txNo) {
                if (parseInt(txData?.txNo) > maxNoff) {
                  maxNoff = parseInt(txData?.txNo)
                }
              }
            })

            setTxNosArr(newArr);
            setMaxNo(maxNoff)
          }

          // to get transaction full data
          setTx(data?.resTxData)

          // to get chart accounts
          let locExpenseChartAccount = []
          let locIncomeChartAccount = []
          data?.resChartAccounts && data.resChartAccounts.map((acc) => {
            if (acc?.classification === "Income") {
              locIncomeChartAccount.push(acc)
            } else if (acc?.topLevel === "Expense") {
              locExpenseChartAccount.push(acc)
            }
          })
          setChartAccounts(locIncomeChartAccount)
          setIncomeChartAccounts(locIncomeChartAccount)
          setExpenseChartAccounts(locExpenseChartAccount)

          // to get bank accounts
          const getBankChartAccs = data?.rexBankAccounts
          const locBankAccs = [addObject, ...getBankChartAccs]
          setBankChartAccounts(locBankAccs)

          setTxLoading(false)
        })
        .catch((err) => {
          console.log(err);
        })
    } else if ((curUrl.includes("credit-note") || curUrl.includes("bill")) && curUrl.includes("edit")) {
      setTxLoading(true)
      getFullTxData({
        walletId,
        txId,
        type: "Bill",
        own: false,
        isView: false
      })
        .then((data) => {
          console.log(data)
          const addObject = {
            _id: 'New',
            name: "+ Add New",
            numDays: "0"
          };

          // to get products and services
          const offeringRelations = data?.resProductsAndServices || []
          let newOfferingsArr = [addObject, ...offeringRelations]
          setOfferings(newOfferingsArr);

          // to get tx no array
          if (data?.resTxsForTxNoEdit && data.resTxsForTxNoEdit.length > 0) {
            let newArr = []
            let maxNoff = 1
            data.resTxsForTxNoEdit.map((txData) => {
              if (txData?.txNo && txData?.txNo !== data?.resTxData?.txNo) {
                newArr.push(txData.txNo)
              }
              if (txData?.txNo) {
                if (parseInt(txData?.txNo) > maxNoff) {
                  maxNoff = parseInt(txData?.txNo)
                }
              }
            })

            setTxNosArr(newArr);
            setMaxNo(maxNoff)
          }

          // to get transaction full data
          setTx(data?.resTxData)

          // to get chart accounts
          let locExpenseChartAccount = []
          let locIncomeChartAccount = []
          data?.resChartAccounts && data.resChartAccounts.map((acc) => {
            if (acc?.classification === "Income") {
              locIncomeChartAccount.push(acc)
            } else if (acc?.topLevel === "Expense") {
              locExpenseChartAccount.push(acc)
            }
          })
          setChartAccounts(locExpenseChartAccount)
          setIncomeChartAccounts(locIncomeChartAccount)
          setExpenseChartAccounts(locExpenseChartAccount)

          // to get bank accounts
          const getBankChartAccs = data?.rexBankAccounts
          const locBankAccs = [addObject, ...getBankChartAccs]
          setBankChartAccounts(locBankAccs)

          setTxLoading(false)
        })
        .catch((err) => {
          console.log(err);
        })
    } else if ((curUrl.includes("debit-note") || curUrl.includes("invoice")) && curUrl.includes("view")) {
      setTxLoading(true)
      getFullTxData({
        walletId,
        txId,
        type: "Invoice",
        own: true,
        isView: true
      })
        .then((data) => {
          setTx(data?.resTxData)

          setTxLoading(false)
        })
        .catch((err) => {
          console.log(err);
        })
    } else if ((curUrl.includes("credit-note") || curUrl.includes("bill")) && curUrl.includes("view")) {
      setTxLoading(true)
      getFullTxData({
        walletId,
        txId,
        type: "Bill",
        own: false,
        isView: true
      })
        .then((data) => {
          setTx(data?.resTxData)

          setTxLoading(false)
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }, [walletId, txId])

  useEffect(() => {
    console.log(tx)
    let locActiveStage = tx?.approvalRequest?.activeStage || null
    setActiveStage(locActiveStage)
    let canEdit = false
    if (locActiveStage?.approvalType === "anybody") {
      canEdit = true
    } else {
      let approvers = locActiveStage?.approvers || [];
      for (let i = 0; i < approvers.length; i++) {
        if (approvers[i].profile == user?.profile && !approvers[i]?.isApproved && !approvers[i]?.isRejected) {
          canEdit = true;
          break;
        }
      }
    }
    setNeedToMakeStatusDisable(!canEdit)
    if (locActiveStage?.canEditMainData && canEdit) {
      setNeedToMakeDisable(false)
    } else {
      setNeedToMakeDisable(true)
    }
  }, [tx?.approvalRequest?.activeStage])

  let TxView = null;
  switch (tx?.type) {
    case "DebitNote":
      TxView = <DebitNoteEdit
        tx={tx}
        setTx={setTx}
        discounts={discounts}
        setDiscounts={setDiscounts}
        taxes={taxes}
        setTaxes={setTaxes}
        chartAccounts={chartAccounts}
        setChartAccounts={setChartAccounts}
        offerings={offerings}
        setOfferings={setOfferings}
        terms={terms}
        setTerms={setTerms}
        txNosArr={txNosArr}
        setTxNosArr={setTxNosArr}
        incomeChartAccounts={incomeChartAccounts}
        setIncomeChartAccounts={setIncomeChartAccounts}
        expenseChartAccounts={expenseChartAccounts}
        setExpenseChartAccounts={setExpenseChartAccounts}
        maxNo={maxNo}
        setMaxNo={setMaxNo}
        updateApi={updateTx}
        walletId={walletId}
        bankChartAccounts={bankChartAccounts}
        setBankChartAccounts={setBankChartAccounts}
        needToMakeDisable={needToMakeDisable}
        activeStage={activeStage}
        needToMakeStatusDisable={needToMakeStatusDisable}
      />
      break;
    case "CreditNote":
      TxView = <CreditNoteEdit
        tx={tx}
        setTx={setTx}
        discounts={discounts}
        setDiscounts={setDiscounts}
        taxes={taxes}
        setTaxes={setTaxes}
        chartAccounts={chartAccounts}
        setChartAccounts={setChartAccounts}
        offerings={offerings}
        setOfferings={setOfferings}
        terms={terms}
        setTerms={setTerms}
        txNosArr={txNosArr}
        setTxNosArr={setTxNosArr}
        incomeChartAccounts={incomeChartAccounts}
        setIncomeChartAccounts={setIncomeChartAccounts}
        expenseChartAccounts={expenseChartAccounts}
        setExpenseChartAccounts={setExpenseChartAccounts}
        maxNo={maxNo}
        setMaxNo={setMaxNo}
        updateApi={updateTx}
        walletId={walletId}
        bankChartAccounts={bankChartAccounts}
        setBankChartAccounts={setBankChartAccounts}
        needToMakeDisable={needToMakeDisable}
        activeStage={activeStage}
        needToMakeStatusDisable={needToMakeStatusDisable}
      />
      break;
    case 'Bill':
      TxView = <BillEdit
        tx={tx}
        setTx={setTx}
        discounts={discounts}
        setDiscounts={setDiscounts}
        taxes={taxes}
        setTaxes={setTaxes}
        chartAccounts={chartAccounts}
        setChartAccounts={setChartAccounts}
        offerings={offerings}
        setOfferings={setOfferings}
        terms={terms}
        setTerms={setTerms}
        txNosArr={txNosArr}
        setTxNosArr={setTxNosArr}
        incomeChartAccounts={incomeChartAccounts}
        setIncomeChartAccounts={setIncomeChartAccounts}
        expenseChartAccounts={expenseChartAccounts}
        setExpenseChartAccounts={setExpenseChartAccounts}
        maxNo={maxNo}
        setMaxNo={setMaxNo}
        updateApi={updateTx}
        walletId={walletId}
        bankChartAccounts={bankChartAccounts}
        setBankChartAccounts={setBankChartAccounts}
        needToMakeDisable={needToMakeDisable}
        activeStage={activeStage}
        needToMakeStatusDisable={needToMakeStatusDisable}
      />
      break;
    case 'Invoice':
      TxView = <InvoiceEdit
        tx={tx}
        setTx={setTx}
        discounts={discounts}
        setDiscounts={setDiscounts}
        taxes={taxes}
        setTaxes={setTaxes}
        chartAccounts={chartAccounts}
        setChartAccounts={setChartAccounts}
        offerings={offerings}
        setOfferings={setOfferings}
        terms={terms}
        setTerms={setTerms}
        txNosArr={txNosArr}
        setTxNosArr={setTxNosArr}
        incomeChartAccounts={incomeChartAccounts}
        setIncomeChartAccounts={setIncomeChartAccounts}
        expenseChartAccounts={expenseChartAccounts}
        setExpenseChartAccounts={setExpenseChartAccounts}
        maxNo={maxNo}
        setMaxNo={setMaxNo}
        updateApi={updateTx}
        walletId={walletId}
        bankChartAccounts={bankChartAccounts}
        setBankChartAccounts={setBankChartAccounts}
        needToMakeDisable={needToMakeDisable}
        activeStage={activeStage}
        needToMakeStatusDisable={needToMakeStatusDisable}
      />
      break;
    default:
      break;
  }

  return (
    <div className={classes.root}>
      {txLoading ? (<TxSeleton isInvoice={true} />) : TxView}
    </div>
  );
}