import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, TextField, InputAdornment, Avatar } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import LinearProgress from "@material-ui/core/LinearProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Search } from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import emptyPage from "../../../Assets/EmptyPageSVG.svg"
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyPopOver from "../../styled/CommonComponents/MyPopOver";
import { createProcurementApiCall, getOpsOrders, getProfileData, getProfileDataPopulate } from "./api.call";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../styled/agGrid.css";
import { useDebounce } from "react-use";
import moment from "moment";
import { createDefaultTemplateHook } from "../../approval/approvalHelper";
import { createCertificateApiCall, createTx } from "../../finance2o/commonComponent/transaction/api";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        backgroundColor: "white",
    },
    topBar: {
        width: "100%",
        height: "60px",
        borderBottom: "1px solid #ececec",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    barLeft: {
        display: "flex",
        alignItems: "center"
    },
    backArrow: {
        fontSize: "30px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "20px",
        },
    },
    headerTitle: {
        fontSize: "18px",
        fontWeight: "550",
        [theme.breakpoints.down("sm")]: {
            fontSize: "15px",
            fontWeight: "510",
        },
    },
    barRight: {
        display: "flex",
        alignItems: "center",
        paddingRight: "20px",
        "& .MuiButton-label": {
            padding: "0px 1px",
            textTransform: "capitalize",
        }
    },
    optionsCont: {
        padding: "8px",
    },
    singleOption: {
        padding: "5px 10px",
        textAlign: "left",
        cursor: "pointer",
        fontSize: "15px",
        fontWeight: "500",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    },
    loaderCont: {
        width: "100%",
        height: "5px",
    },
    bodyCont: {
        width: "100%",
        height: "calc(100vh - 65px)",
        overflowY: "auto",
        overflowX: "hidden"
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #d2d1d1",
    },
    tableContainer: {
        width: "100%",
        height: "calc(100% - 50px)",
        "& tr": {
            height: "45px"
        },
        "& table": {
            tableLayout: "auto"
        }
    },
    nameEmailSty: {
        width: "100%",
        paddingLeft: "10px",
        "& h3": {
            fontSize: "14px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "11px",
            fontWeight: "400",
            color: "gray"
        }
    },
    tableRow: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec !important"
        }
    },
    emptyLibrarayCont: {
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            width: "200px",
            height: "auto",
        },
        "& div": {
            width: "100%",
            marginTop: "10px",
            padding: "0px 20px",
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            color: "gray",
            textAlign: "center",
        },
        "& a": {
            textDecoration: "none",
            fontSize: "14px",
            marginLeft: "5px",
            cursor: "pointer",
            color: "blue"
        }
    },
}));

const opsTypesList = ["Material Request", "Bill Of Quantities", "Purchase Order", "Work Order", "GRN", "Certificate", "Credit Note", "Debit Note"]

const ProcurementNew = (props) => {
    const { profileId } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [opsTypes, setOpsTypes] = useState([]);
    const [profileData, setProfileData] = useState(null)
    const [loader, setLoader] = useState(false)
    const [totalPages, setTotalPages] = useState(0)
    const [totalResultsCount, setTotalResultsCount] = useState(0)
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)
    const [searchQuery, setSearchQuery] = useState("");
    const [changeState, setChangeState] = useState()
    const [opsOrder, setOpsOrder] = useState([])
    const [defaultCreateLoader, setDefaultCreateLoader] = useState(false)

    //get details data of current scope
    const getProjectData = async () => {
        await getProfileDataPopulate({ profileId })
            .then((data) => {
                console.log(data)
                setProfileData(data)
                let accessableTypes = [];
                opsTypesList.map((type) => {
                    if (type === "Material Request") {
                        if (data?.parent?.wallet?.defaultLibrary?.defaultMaterialRequestApproval?.stages && data.parent.wallet.defaultLibrary.defaultMaterialRequestApproval.stages.length > 0) {
                            const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultMaterialRequestApproval?.stages[0];
                            const approversArr = initialStage.approvers.map((approver) => approver?.profile)
                            if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
                                accessableTypes.push(type)
                            }
                        }
                    } else if (type === "Bill Of Quantities") {
                        if (data?.parent?.wallet?.defaultLibrary?.defaultBillOfQuantitiesApproval?.stages && data.parent.wallet.defaultLibrary.defaultBillOfQuantitiesApproval.stages.length > 0) {
                            const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultBillOfQuantitiesApproval?.stages[0];
                            const approversArr = initialStage.approvers.map((approver) => approver?.profile)
                            if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
                                accessableTypes.push(type)
                            }
                        }
                    } else if (type === "Purchase Order") {
                        if (data?.parent?.wallet?.defaultLibrary?.defaultPurchaseOrderApproval?.stages && data.parent.wallet.defaultLibrary.defaultPurchaseOrderApproval.stages.length > 0) {
                            const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultPurchaseOrderApproval?.stages[0];
                            const approversArr = initialStage.approvers.map((approver) => approver?.profile)
                            if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
                                accessableTypes.push(type)
                            }
                        }
                    } else if (type === "Work Order") {
                        if (data?.parent?.wallet?.defaultLibrary?.defaultWorkOrderApproval?.stages && data.parent.wallet.defaultLibrary.defaultWorkOrderApproval.stages.length > 0) {
                            const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultWorkOrderApproval?.stages[0];
                            const approversArr = initialStage.approvers.map((approver) => approver?.profile)
                            if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
                                accessableTypes.push(type)
                            }
                        }
                    } else if (type === "GRN") {
                        if (data?.parent?.wallet?.defaultLibrary?.defaultGRNApproval?.stages && data.parent.wallet.defaultLibrary.defaultGRNApproval.stages.length > 0) {
                            const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultGRNApproval?.stages[0];
                            const approversArr = initialStage.approvers.map((approver) => approver?.profile)
                            if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
                                accessableTypes.push(type)
                            }
                        }
                    } else if (type === "Certificate") {
                        if (data?.parent?.wallet?.defaultLibrary?.defaultFinancialCertificateApproval?.stages && data.parent.wallet.defaultLibrary.defaultFinancialCertificateApproval.stages.length > 0) {
                            const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultFinancialCertificateApproval?.stages[0];
                            const approversArr = initialStage.approvers.map((approver) => approver?.profile)
                            if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
                                accessableTypes.push(type)
                            }
                        }
                    } else if (type === "Credit Note" || type === "Debit Note") {
                        if (data?.parent?.wallet?.defaultLibrary?.defaultDebitCreditNoteApproval?.stages && data.parent.wallet.defaultLibrary.defaultDebitCreditNoteApproval.stages.length > 0) {
                            const initialStage = data?.parent?.wallet?.defaultLibrary?.defaultDebitCreditNoteApproval?.stages[0];
                            const approversArr = initialStage.approvers.map((approver) => approver?.profile)
                            if (initialStage?.approvalType === "anybody" || (approversArr && approversArr.length > 0 && approversArr.includes(user?.profile))) {
                                accessableTypes.push(type)
                            }
                        }
                    }
                })
                setOpsTypes(accessableTypes)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (profileId) {
            getProjectData()
        }
    }, [profileId])

    //final create api call
    const createProcurement = async (opsType) => {
        setLoader(true)
        if (opsType === "Certificate") {
            let createData = {
                user: user?._id,
                walletId: profileData?.parent?.wallet?._id,
                profile: profileData?._id,
                userProfile: user?.profile,
                libraryId: profileData?.parent?.wallet?.defaultLibrary?._id,
                defaultTemplate: profileData?.parent?.wallet?.defaultLibrary?.defaultFinancialCertificateApproval?._id,
            }
            if (profileData?.parentModelName === "Project") {
                createData.projectParent = profileData?.parent?.ownerProfile?.parent;
                createData.projectParentModel = profileData?.parent?.ownerProfile?.parentModelName;
                createData.project = profileData?.parent?._id;
            } else {
                createData.projectParent = profileData?.parent?._id;
                createData.projectParentModel = profileData?.parentModelName;
            }
            if (profileData?.parentModelName === "Organization") {
                createData.orgId = profileData?.parent?._id
            } else if (profileData?.parent?.ownerProfile?.parentModelName === "Organization") {
                createData.orgId = profileData?.parent?.ownerProfile?.parent
            }
            await createCertificateApiCall(createData)
                .then((data) => {
                    console.log(data)
                    history.push(`/procurements/${profileData?.parent?.wallet?._id}/workorder-certificate/edit/${data?._id}`);
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(()=>{
                    setLoader(false)
                })
        } else if (opsType === "Credit Note" || opsType === "Debit Note") {
            let type = "";
            if (opsType === "Credit Note") {
                type = "CreditNote";
            } else if (opsType === "Debit Note") {
                type = "DebitNote";
            }
            let createData = {
                type,
                status: "Draft",
                firstPartyWallet: profileData?.parent?.wallet?._id,
                firstParty: profileData?._id,
                user: user?._id,
                creatorUser: user?._id,
                creatorProfile: user?.profile,
                profile: profileData?._id,
                libraryId: profileData?.parent?.wallet?.defaultLibrary?._id,
                defaultTemplate: profileData?.parent?.wallet?.defaultLibrary?.defaultDebitCreditNoteApproval?._id,
                isAccountingNeed: false
            }
            if (profileData?.parentModelName === "Project") {
                createData.projectParent = profileData?.parent?.ownerProfile?.parent;
                createData.projectParentModel = profileData?.parent?.ownerProfile?.parentModelName;
                createData.project = profileData?.parent?._id;
            } else {
                createData.projectParent = profileData?.parent?._id;
                createData.projectParentModel = profileData?.parentModelName;
            }
            if (profileData?.parentModelName === "Organization") {
                createData.orgId = profileData?.parent?._id
            } else if (profileData?.parent?.ownerProfile?.parentModelName === "Organization") {
                createData.orgId = profileData?.parent?.ownerProfile?.parent
            }
            const data = await createTx(createData)
            setLoader(false)
            if (data?._id) {
                let path = "";
                if (type === "DebitNote") {
                    path = "/procurements/" + profileData?.parent?.wallet?._id + "/debit-note/edit/" + data?._id;
                } else if (type === "CreditNote") {
                    path = "/procurements/" + profileData?.parent?.wallet?._id + "/credit-note/edit/" + data?._id;
                }
                history.push(path);
            }
        } else {
            let newObj = {
                profile: profileData?._id,
                opsType: opsType,
                userProfile: user?.profile,
                libraryId: profileData?.parent?.wallet?.defaultLibrary?._id,
                userId: user._id
            };
            if (opsType === "Material Request") {
                newObj.defaultTemplate = profileData?.parent?.wallet?.defaultLibrary?.defaultMaterialRequestApproval;
            } else if (opsType === "Bill Of Quantities") {
                newObj.defaultTemplate = profileData?.parent?.wallet?.defaultLibrary?.defaultBillOfQuantitiesApproval;
            } else if (opsType === "Purchase Order") {
                newObj.defaultTemplate = profileData?.parent?.wallet?.defaultLibrary?.defaultPurchaseOrderApproval;
            } else if (opsType === "Work Order") {
                newObj.defaultTemplate = profileData?.parent?.wallet?.defaultLibrary?.defaultWorkOrderApproval;
            } else if (opsType === "GRN") {
                newObj.defaultTemplate = profileData?.parent?.wallet?.defaultLibrary?.defaultGRNApproval;
            }
            if (profileData?.parentModelName === "Project") {
                newObj.projectParent = profileData?.parent?.ownerProfile?.parent;
                newObj.projectParentModel = profileData?.parent?.ownerProfile?.parentModelName;
                newObj.project = profileData?.parent?._id;
            } else {
                newObj.projectParent = profileData?.parent?._id;
                newObj.projectParentModel = profileData?.parentModelName;
            }
            if (profileData?.parentModelName === "Organization") {
                newObj.orgId = profileData?.parent?._id
            } else if (profileData?.parent?.ownerProfile?.parentModelName === "Organization") {
                newObj.orgId = profileData?.parent?.ownerProfile?.parent
            }
            await createProcurementApiCall(newObj)
                .then((data) => {
                    console.log("data:", data);
                    history.push(`/procurements/ops-order/${data._id}/edit`);
                    dispatch({
                        type: "AddApiAlert",
                        payload: {
                            success: true,
                            message: `${opsType} created successfully`,
                        },
                    });
                    setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                    setLoader(false)
                })
        }
    }

    const getAllProcurements = async () => {
        if (profileData && profileData?.parent?._id) {
            setLoader(true)
            await getOpsOrders({
                identifier: profileData?.parent?._id,
                searchStr: searchQuery,
                curPage: curPage - 1,
                limit: pageLimit
            })
                .then((data) => {
                    console.log("The procurement data:", data)
                    setOpsOrder(data?.data || [])
                    setTotalResultsCount(data?.count || 0)
                    let locTotalPage = Math.ceil((data?.count || 0) / pageLimit);
                    setTotalPages(locTotalPage)
                    setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                    setLoader(false)
                })
        }
    }

    useDebounce(() => {
        getAllProcurements()
    }, 1000, [curPage, profileData, changeState])

    useEffect(() => {
        setCurPage(1)
        setChangeState(!changeState)
    }, [searchQuery, profileData, profileData?.parent?._id])

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const createDefaultsTemplates = async () => {
        setDefaultCreateLoader(true)
        const entity = profileData?.parent?._id;
        const entityModelName = profileData?.parentModelName;
        const entityParent = profileData?.parent?.ownerProfile?.parent;
        const entityParentModelName = profileData?.parent?.ownerProfile?.parentModelName;
        await createDefaultTemplateHook({
            profileId: profileId,
            entity,
            entityModelName,
            entityParent,
            userId: user?._id,
            entityParentModelName,
            ownerProfileId: profileData?.parent?.ownerProfile?._id,
            types: ["Material Request", "Bill Of Quantities", "Purchase Order", "Work Order", "GRN", "Financial Certificate", "Debit/Credit Note"],
            libraryId: profileData?.parent?.wallet?.defaultLibrary?._id
        })
            .then(async (data) => {
                console.log(data)
                await getProjectData();
                setDefaultCreateLoader(false)
            })
            .catch(async (error) => {
                console.log(error)
                await getProjectData();
                setDefaultCreateLoader(false)
            })
    }

    return (<>
        {!profileData?.parent?.wallet?.defaultLibrary?._id ? (
            <div className={classes.emptyLibrarayCont} >
                <img src={emptyPage} />
                <div>
                    <p>
                        No Library exists in the current directory or any library still didn't selected as default library.<br />
                        Click <a
                            onClick={() => {
                                history.push(`/offerings-categories/management/${profileId}`)
                            }}
                        >here</a> to create and manage your library(s), category(s), product(s) and service(s)
                    </p>
                </div>
            </div>
        ) : (<>{(
            !profileData?.parent?.wallet?.defaultLibrary?.defaultMaterialRequestApproval ||
            !profileData?.parent?.wallet?.defaultLibrary?.defaultBillOfQuantitiesApproval ||
            !profileData?.parent?.wallet?.defaultLibrary?.defaultPurchaseOrderApproval ||
            !profileData?.parent?.wallet?.defaultLibrary?.defaultWorkOrderApproval ||
            !profileData?.parent?.wallet?.defaultLibrary?.defaultGRNApproval) ? (
            <div className={classes.emptyLibrarayCont} >
                <img src={emptyPage} />
                <div>
                    <p>{defaultCreateLoader ? `Template Creating....` : (<>
                        Some template(s) are missing in the current directory or some template(s) are still didn't selected as default.<br />
                        Click <a
                            onClick={createDefaultsTemplates}
                        >here</a> to create default template(s)
                    </>)}
                    </p>
                </div>
            </div>
        ) : (
            <div className={classes.root} >
                <div className={classes.topBar} >
                    <div className={classes.barLeft} >
                        <IconButton onClick={() => { history.goBack() }} >
                            <KeyboardBackspaceIcon className={classes.backArrow} />
                        </IconButton>
                        <h3 className={classes.headerTitle}>Procurements({totalResultsCount})</h3>
                    </div>
                    <div className={classes.barRight} >
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "10px" }}
                        >
                            Analytics
                        </Button>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder="Search..."
                            value={searchQuery}
                            style={{ width: "300px", marginRight: "10px" }}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {opsTypes && opsTypes.length > 0 ? (
                            <MyPopOver
                                closeOnClick={true}
                                appearContent={
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={loader}
                                        endIcon={<ExpandMoreIcon />}
                                    >
                                        Create
                                    </Button>
                                }
                                showContent={
                                    <div className={classes.optionsCont} >
                                        {opsTypes.map((item) => (
                                            <div
                                                className={classes.singleOption}
                                                onClick={() => { createProcurement(item) }}
                                            >
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                }
                            />
                        ) : null}
                    </div>
                </div>
                <div className={classes.loaderCont} >
                    {loader && (<LinearProgress />)}
                </div>
                <div className={classes.bodyCont} >
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="sticky table" size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" style={{ minWidth: "170px" }} >
                                        Name
                                    </TableCell>
                                    <TableCell align="left" style={{ minWidth: "100px" }} >
                                        Status
                                    </TableCell>
                                    <TableCell align="left" style={{ minWidth: "100px" }} >
                                        Type
                                    </TableCell>
                                    <TableCell align="left" style={{ minWidth: "120px" }} >
                                        Total Amount
                                    </TableCell>
                                    <TableCell align="left" style={{ minWidth: "120px" }} >
                                        Certified Amount
                                    </TableCell>
                                    <TableCell align="left" style={{ minWidth: "230px" }} >
                                        Project
                                    </TableCell>
                                    <TableCell align="left" style={{ minWidth: "230px" }} >
                                        Created By
                                    </TableCell>
                                    <TableCell align="right" style={{ minWidth: "120px" }} >
                                        Created At
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {opsOrder && opsOrder.length > 0 && opsOrder.map((dataRow) => {
                                    return (
                                        <TableRow
                                            className={classes.tableRow}
                                            onClick={() => {
                                                history.push(`/procurements/ops-order/${dataRow?._id}/edit`)
                                            }}
                                        >
                                            <TableCell align="left" style={{ minWidth: "170px" }} >
                                                {dataRow?.name}
                                            </TableCell>
                                            <TableCell align="left" style={{ minWidth: "100px" }} >
                                                {dataRow?.status}
                                            </TableCell>
                                            <TableCell align="left" style={{ minWidth: "100px" }} >
                                                {dataRow?.type}
                                            </TableCell>
                                            <TableCell align="left" style={{ minWidth: "120px" }} >
                                                ₹{numberWithCommas(dataRow?.totalAmountVal || 0)}
                                            </TableCell>
                                            <TableCell align="left" style={{ minWidth: "120px" }} >
                                                ₹{numberWithCommas(dataRow?.totalCertifiedAmount || 0)}
                                            </TableCell>
                                            <TableCell align="left" style={{ minWidth: "230px" }} >
                                                {dataRow?.project?.displayName && (
                                                    <div style={{ display: "flex", alignItems: "center" }} >
                                                        <Avatar style={{ width: "30px", height: "30px" }} src={dataRow?.project?.displayPicture?.url} />
                                                        <div className={classes.nameEmailSty} >
                                                            <h3>{dataRow?.project?.displayName}</h3>
                                                            <p>{dataRow?.project?.email}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell align="left" style={{ minWidth: "230px" }} >
                                                {dataRow?.user?.displayName && (
                                                    <div style={{ display: "flex", alignItems: "center" }} >
                                                        <Avatar style={{ width: "30px", height: "30px" }} src={dataRow?.user?.displayPicture?.url} />
                                                        <div className={classes.nameEmailSty} >
                                                            <h3>{dataRow?.user?.displayName}</h3>
                                                            <p>{dataRow?.user?.email}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell align="right" style={{ minWidth: "120px" }} >
                                                {moment(dataRow?.created).format("DD/MMM/YYYY")}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={classes.paginationCont} >
                        <Pagination
                            count={totalPages}
                            page={curPage}
                            color="primary"
                            siblingCount={0}
                            onChange={(event, value) => { setCurPage(value) }}
                        />
                    </div>

                </div>
            </div>
        )}
        </>
        )}
    </>
    );
};

export default ProcurementNew;