import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../../styled/agGrid.css";
import BillListEdit from "./Bill.List.Edit";

const useStyles = makeStyles((theme) => ({

}));


const BillItemsTable = ({
    billListId, wallet, projectProfileId, setBillListError, setBillListTotals, billListTotals,
    needToReloadBillList, setNeedToReloadBillList, needToMakeDisable = false
}) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    return (
        <BillListEdit
            billListId={billListId}
            wallet={wallet}
            projectProfileId={projectProfileId}
            setBillListError={setBillListError}
            setBillListTotals={setBillListTotals}
            billListTotals={billListTotals}
            needToReloadBillList={needToReloadBillList}
            needToMakeDisable={needToMakeDisable}
        />
    );
};

export default BillItemsTable;