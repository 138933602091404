import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { useMediaQuery, TextField, Tab, Drawer } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import IconButton from "@material-ui/core/IconButton";
import DialogBillNo from "../expenseAndBills/bills/DialogBillNo";
import { handleTxReject, submitMultipleTransaction } from "../commonComponent/transaction/api";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { getIncomeChartAccounts } from "../productsAndServices/utils";
import ChartAccountCreateForBank from "./ChartAccountCreateForBank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import { useDebounce } from "react-use";
import "./index.css";
import AttachmentsHandler from "../../styled/CommonComponents/Attachments.Handler";
import ExportPDF from "../../styled/generic/ExportPDF";
import BlocksAndTasksHandler from './BlocksAndTasksHandler';
import ProjectPicker from "../../styled/generic/ProjectPicker";
import { MdOutlineForkRight } from "react-icons/md";
import { getTx } from "../commonComponent/payBackTx/api";
import ApprovalAndActions from "../../approval/ApprovalAndActions";
import LockIcon from '@material-ui/icons/Lock';
import MyPopOver from "../../styled/CommonComponents/MyPopOver";
import { ArrowDropDown } from "@material-ui/icons";
import { allStatusOptions } from "../../../helpers/allStatuses";
import ApprovalActionDrawer from "../../approval/ApprovalActionDrawer";

const paymentTypes = ["Cash", "Cheque"];

const useStyles = makeStyles((theme) => ({
  // ... (keep all the existing styles)
  root: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  editIconSty: {
    fontSize: "25px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  invoiceEditBody: {
    width: "100%",
    height: "calc(100% - 50px)",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "0px 25px 100px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 50px",
    },
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
    paddingRight: "20px",
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  nextIcon: {
    transform: "rotate(180deg)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
  emptyCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      height: "250px",
      width: "auto",
      marginBottom: "10px",
    },
    "& p": {
      fontSize: "14px",
      color: "gray",
    },
  },
  sectionTop: {
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "15px",
    padding: "5px",
    marginTop: "50px",
    "& h3": {
      fontSize: "21px",
      fontWeight: "600",
      color: "#193B56",
    },
    [theme.breakpoints.down("sm")]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
  },
  autocompleteSty: {
    width: "400px",
    margin: "10px 0px 15px",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  tabsPanel: {
    marginTop: "30px",
    borderBottom: "1px solid #d0cfcf",
    width: "100%",
  },
  bodySectionCont: {
    width: "100%",
    height: "300px",
    overflowX: "hidden",
    position: "relative",
  },
  offlinePaymentCont: {
    width: "100%",
    position: "absolute",
    top: "0px",
    left: "0px",
  },
  textSty: {
    marginTop: "20px",
    "& p": {
      fontSize: "13px",
      color: "gray",
    },
  },
  paidBtnCont: {
    marginTop: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  typeTitleSty: {
    fontSize: "16px",
    fontWeight: "510",
    margin: "20px 0px 0px",
  },
  emptyCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      height: "250px",
      width: "auto",
      marginBottom: "10px",
    },
    "& p": {
      fontSize: "14px",
      color: "gray",
    },
  },
  noBorder: {
    border: "none",
  },
  input: {
    backgroundColor: "#ebebeb",
    padding: "10px",
  },
  memoStyle: {
    width: "100%",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
  },

  projectContainer: {
    width: "100%",
  },

  projectBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "15px",
    padding: "5px",
    marginTop: "50px",
    "& h3": {
      fontSize: "21px",
      fontWeight: "600",
      color: "#193B56",
    },
    [theme.breakpoints.down("sm")]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
  },
  optionsCont: {
    padding: "5px",
  },
  typeSingleOptionSty: {
    padding: "4px 15px",
    fontSize: "16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  }
}));
const CustomTab = withStyles({
  root: {
    textTransform: "none",
    fontSize: "1rem",
    minWidth: 50,
  },
})(Tab);

export default function InvoicePaymentSettings({
  tx, setTx, txNosArr, setTxNosArr, maxNo, setMaxNo, updateApi,
  walletId, setOpenPayment, setLoadingBool, loadingBool, bankChartAccounts,
  setBankChartAccounts, openPayment, needToMakeDisable, activeStage,
  needToMakeStatusDisable
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { user } = useSelector((state) => state.auth);

  const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const [selectProject, setSelectedProject] = useState({});

  const [open, setOpen] = useState(false);
  const [selectedBankChartAccount, setSelectedBankChartAccount] = useState(tx?.BankChartAccount || null);
  const [openChartAccCreate, setOpenChartAccCreate] = useState(false);
  const [paymentTypeValue, setPaymentTypeValue] = useState(tx?.paymentBy || null);
  const [openErr, setOpenErr] = useState(false);
  const [submitionFailMsg, setSubmitionFailMsg] = useState("");
  const [memoText, setMemoText] = useState(tx?.memo);
  const [initialDStopper2, setInitialDStopper2] = useState(false);
  const [openApprovalFlow, setOpenApprovalFlow] = useState(false)
  const [openAction, setOpenAction] = useState(false)
  const [actionStatus, setActionStatus] = useState("")


  // Remove the states and functions that are now handled by BlocksAndTasksHandler

  const update = async (obj, isBankChartUpdate = false) => {
    setLoadingBool(true);

    if (isBankChartUpdate) {
      await updateApi({
        _id: obj?._id,
        BankChartAccount: obj?.BankChartAccount?._id,
      });

      setTx({
        ...tx,
        BankChartAccount: obj?.BankChartAccount,
      });
    } else {
      await updateApi(obj);

      setTx({
        ...tx,
        ...obj,
      });
    }

    setLoadingBool(false);
  };

  const paymentTypeSelect = async (value) => {
    setPaymentTypeValue(value);

    await update(
      {
        _id: tx?._id,
        paymentBy: value,
      },
      false
    );
  };

  const onSelectChartAccount = async (value) => {
    setSelectedBankChartAccount(value);

    await update(
      {
        _id: tx?._id,
        BankChartAccount: value,
      },
      true
    );
  };

  const chartAccountCreateDialog = async (value) => {
    setOpenChartAccCreate(value);

    const type = { classification: "Bank", wallet: walletId };

    getIncomeChartAccounts({ type })
      .then(async (accounts) => {
        const addObject = {
          _id: "New",
          name: "+ Add New",
          numDays: "0",
        };

        const newFiltered = [addObject, ...accounts];

        if (newFiltered.length > bankChartAccounts.length) {
          const len = accounts?.length;
          setBankChartAccounts(newFiltered);
          setSelectedBankChartAccount(accounts[len - 1]);

          await update(
            {
              _id: tx?._id,
              BankChartAccount: accounts[len - 1],
            },
            true
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // to submit transaction only
  const txSubmit = async () => {
    setLoadingBool(true);
    const tx_id = tx?._id;
    await submitMultipleTransaction({
      txIdPurposeObj: {
        [tx_id]: actionStatus,
      },
    })
      .then((data) => {
        console.log("data");
      })
      .catch((err) => {
        console.log(err);
      });
    setLoadingBool(false);
  };

  // to mark a transaction as paid
  const markAsPaid = async () => {
    setLoadingBool(true);
    const tx_id = tx?._id;
    await submitMultipleTransaction({
      txIdPurposeObj: {
        [tx_id]: actionStatus,
      },
    })
      .then((data) => {
        let updatedTx = {
          ...tx,
          status: actionStatus,
          processed: true,
        };
        setTx(updatedTx);
        setLoadingBool(false);
        setOpenPayment(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useDebounce(
    () => {
      if (initialDStopper2 && tx?.memo !== memoText) {
        update(
          {
            _id: tx?._id,
            memo: memoText,
          },
          false
        );
      } else {
        setInitialDStopper2(true);
      }
    },
    1000,
    [memoText]
  );

  const updateStatus = (statusVal) => {
    let taxItemBool = false;
    let isItemMissChart = false;

    let items = tx?.billList?.items;
    if (!tx?.secondParty || !tx?.secondPartyWallet) {
      setSubmitionFailMsg("Customer is missing! Please select a customer");
      setOpenErr(true);
      return;
    }

    items.length > 0 && items.map((item) => {
      if (item?.tax) {
        taxItemBool = true;
      }

      if (!item?.chartAccount?._id) {
        isItemMissChart = true;
      }
    });

    if (isItemMissChart) {
      setSubmitionFailMsg("item Chart Account is missing! Please select category in item carefully");
      setOpenErr(true);
      return;
    }

    if (tx?.billList?.tax?.enabled && taxItemBool && !tx?.billList?.taxRelationModel?.chartAccount?._id) {
      setSubmitionFailMsg("Tax Chart Account is missing! Please select a tax");
      setOpenErr(true);
      return;
    }

    if (tx?.billList?.discount?.enabled && !tx?.billList?.discountRelationModel?.chartAccount?._id) {
      setSubmitionFailMsg("Discount Chart Account is missing! Please select a discount");
      setOpenErr(true);
      return;
    }

    if (!tx?.BankChartAccount?._id) {
      setSubmitionFailMsg("Please Choose the bank account where the amount will added after the payment done");
      setOpenErr(true);
      return;
    }

    if (paymentTypeValue === null && (statusVal === "Partially Paid" || statusVal === "Paid in Full")) {
      setSubmitionFailMsg("Please select a payment accept type!!");
      setOpenErr(true);
      return;
    }

    setActionStatus(statusVal)
    setOpenAction(true)
  }

  const handleReject = async () => {
    await handleTxReject({ txId: tx?._id })
      .then((data) => {
        console.log(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getFreshData = async () => {
    await getTx(tx?._id)
      .then((data) => {
        console.log(data)
        setTx(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <div className={classes.barLeft}>
          <IconButton
            onClick={() => {
              setOpenPayment(false);
            }}
          >
            <KeyboardBackspaceIcon className={classes.backArrow} />
          </IconButton>
          <Typography className={classes.headerTitle}>
            {walletId === tx?.firstPartyWallet._id ? <>Invoice</> : <>Bill</>}
            <span style={{ marginLeft: "10px" }}>#{tx?.txNo}</span>
          </Typography>
          {!needToMakeDisable && (
            <>
              <IconButton
                color="primary"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <EditIcon className={classes.editIconSty} />
              </IconButton>
              <DialogBillNo
                open={open}
                handleClose={() => {
                  setOpen(false);
                }}
                txNos={txNosArr}
                tx={tx}
                setTx={setTx}
                type={"Invoice"}
                maxNo={maxNo}
                setMaxNo={setMaxNo}
                walletId={walletId}
                setLoadingBool={setLoadingBool}
              />
            </>
          )}
        </div>

        <div className={classes.barRight}>
          <ExportPDF
            apiUrl="/transaction/get/pdf"
            fileName="invoice"
            apiBody={{
              transactionId: tx?._id,
            }}
            enableSignatures={true}
            tx={tx}
            enableTermsAndConditions={true}
            libraryId={tx?.firstPartyWallet?.defaultLibrary}
            profileId={tx?.firstPartyWallet?.profile}
          />
          <Button
            id="demo-customized-button"
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => { setOpenApprovalFlow(true) }}
            endIcon={<MdOutlineForkRight />}
          >
            Approval Flow
          </Button>
          <ApprovalAndActions
            openApprovalFlow={openApprovalFlow}
            setOpenApprovalFlow={setOpenApprovalFlow}
            loadingBool={loadingBool}
            setLoadingBool={setLoadingBool}
            parent={tx?._id}
            parentModelName={"Transaction"}
            needToMakeDisable={needToMakeStatusDisable}
            statusField={"status"}
            activeStage={activeStage}
            statusValue={actionStatus}
            rejectedStatusValue={"Rejected"}
            endStatusValue={"Closed"}
            requestId={tx?.approvalRequest?._id}
          />
          <div style={{ width: "10px" }}></div>
          {needToMakeStatusDisable ? (
            <Button
              id="demo-customized-button"
              variant="outlined"
              size="small"
              color="primary"
              disabled
              endIcon={<LockIcon />}
            >
              {tx?.status ? tx?.status : "STATUS"}
            </Button>
          ) : (<>
            <MyPopOver
              closeOnClick={true}
              appearContent={
                <Button
                  id="demo-customized-button"
                  variant="outlined"
                  size="small"
                  color="primary"
                  endIcon={<ArrowDropDown />}
                >
                  {tx?.status ? tx?.status : "STATUS"}
                </Button>
              }
              showContent={<div className={classes.optionsCont} >
                {allStatusOptions["Transaction"][tx?.status] && allStatusOptions["Transaction"][tx?.status].map((statusOption, i) => (
                  <div
                    key={i}
                    className={classes.typeSingleOptionSty}
                    onClick={() => { updateStatus(statusOption) }}
                  >
                    {statusOption}
                  </div>
                ))}
              </div>}
            />
            <ApprovalActionDrawer
              openAction={openAction}
              setOpenAction={setOpenAction}
              loadingBool={loadingBool}
              actionType={"takeAction"}
              setLoadingBool={setLoadingBool}
              parent={tx?._id}
              noNeedToUpdateParentStatus={true}
              parentModelName={"Transaction"}
              statusField={"status"}
              statusValue={actionStatus}
              rejectedStatusValue={"Rejected"}
              activeStage={activeStage}
              endStatusValue={"Closed"}
              requestId={tx?.approvalRequest?._id}
              actionCallBack={async (type) => {
                if (type === "ApprovalForNextStage") {
                  if (actionStatus === "Submitted" || actionStatus === "Under Review" || actionStatus === "Approved") {
                    await txSubmit()
                  } else if (actionStatus === "Partially Paid" || actionStatus === "Paid") {
                    await markAsPaid()
                  }
                } else if (type === "RequestRejected") {
                  await handleReject()
                }
                await getFreshData()
              }}
            />
          </>)}
        </div>
      </div>
      <div className={classes.invoiceEditBody}>
        <div className={classes.sectionTop} style={{ marginTop: "10px" }}>
          <h3>Bank Chart Account</h3>
        </div>
        <Autocomplete
          value={selectedBankChartAccount}
          options={bankChartAccounts}
          getOptionLabel={(option) => {
            return option?.name || "";
          }}
          getOptionSelected={(option) => {
            return option?._id === selectedBankChartAccount?._id;
          }}
          disabled={needToMakeDisable}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ margin: "0px" }}
              margin="normal"
              variant="outlined"
              label="Bank Chart Account"
              placeholder={"Select bank chart account"}
            />
          )}


          onChange={(event, value) => {
            if (value?._id === "New") {
              setOpenChartAccCreate(true);
            } else {
              onSelectChartAccount(value);
            }
          }}
          className={classes.autocompleteSty}
          size="small"
        />
        {!needToMakeDisable && openChartAccCreate && (
          <ChartAccountCreateForBank
            walletId={walletId}
            openDialog={openChartAccCreate}
            setOpenChart={chartAccountCreateDialog}
          />
        )}
        <p style={{ fontSize: "12px", color: "gray" }}>
          <span style={{ color: "red" }}>*</span>Choose the chart bank account
          where the amount will added after the payment done
        </p>
        <h3 className={classes.typeTitleSty}>Select Payment Accept Type</h3>
        <Autocomplete
          value={paymentTypeValue}
          options={paymentTypes}
          getOptionLabel={(option) => {
            return option || "";
          }}
          getOptionSelected={(option) => {
            return option === paymentTypeValue;
          }}
          disabled={needToMakeDisable}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ margin: "0px" }}
              margin="normal"
              variant="outlined"
              placeholder={"Select payment accept type"}
            />
          )}
          onChange={(event, value) => {
            paymentTypeSelect(value);
          }}
          className={classes.autocompleteSty}
          size="small"
        />

        <div>
          <div className={classes.sectionTop} style={{ marginTop: "15px" }}>
            <h3>Note</h3>
          </div>
          <TextField
            id="outlined-multiline-static"
            variant="outlined"
            placeholder="Enter Memo"
            disabled={needToMakeDisable}
            InputProps={{
              classes: { notchedOutline: classes.noBorder },
              className: classes.input,
            }}
            multiline
            rows={6}
            value={memoText}
            onChange={(e) => {
              setMemoText(e.target.value);
            }}
            className={classes.memoStyle}
          />
          <div className={classes.projectBox}>
            <Typography variant="h3" style={{ marginBottom: "12px" }}>Project</Typography>
            <div>
              <ProjectPicker
                selectedProject={selectProject}
                setSelectedProject={setSelectedProject}
                defaultProjectId={tx?.project || ""}
              />
            </div>
          </div>

          <BlocksAndTasksHandler
            tx={tx}
            setTx={setTx}
            needToMakeDisable={needToMakeDisable}
            setLoadingBool={setLoadingBool}
            walletId={walletId}
            selectProject={selectProject?._id}
            setSelectedProject={setSelectedProject}
            parentModel="Transaction"

          />

          <div className={classes.sectionTop} style={{ marginTop: "15px" }}>
            <h3>Attachments</h3>
          </div>
          {tx?._id && (
            <AttachmentsHandler
              parentData={tx}
              needToMakeDisable={needToMakeDisable}
              setParentData={setTx}
              parentModel={"Transaction"}
              setLoadingBool={setLoadingBool}
            />
          )}
        </div>
      </div>
      <NormalDialog
        openDialog={openErr}
        handleCloseShare={() => {
          setOpenErr(false);
        }}
        pageTitle={"Something is wrong"}
        content={
          <>
            <p style={{ color: "red", textAlign: "center" }}>
              {submitionFailMsg}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <Button
                onClick={() => {
                  setOpenErr(false);
                }}
                color="primary"
              >
                ok
              </Button>
            </div>
          </>
        }
      />
      <DialogBillNo
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        txNos={txNosArr}
        tx={tx}
        setTx={setTx}
        type={"Invoice"}
        maxNo={maxNo}
        setMaxNo={setMaxNo}
        walletId={walletId}
        setLoadingBool={setLoadingBool}
      />
    </div>
  );
}