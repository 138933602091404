import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, Paper, Avatar, InputAdornment } from "@material-ui/core";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import LinearProgress from "@material-ui/core/LinearProgress";
import { ArrowDropDown } from "@material-ui/icons";
import MyPopOver from "../../../styled/CommonComponents/MyPopOver";
import BillItemsTable from "../../../finance2o/commonComponent/Bill.Item.Table/Bill.Items.Table";
import { mainRoles } from '../../../../helpers/contactHelper';
import * as yup from "yup";
import { MdOutlineForkRight } from "react-icons/md";
import { useDebounce } from "react-use";
import NotifyAlert from "../../../styled/CommonComponents/NotifyAlert";
import "../../../styled/agGrid.css";
import InputWrapper from "../../../styled/CommonComponents/InputWarpper";
import AttachmentsHandler from "../../../styled/CommonComponents/Attachments.Handler";
import ApprovalAndActions from "../../../approval/ApprovalAndActions";
import ApprovalActionDrawer from "../../../approval/ApprovalActionDrawer";
import LockIcon from '@material-ui/icons/Lock';
import { allStatusOptions } from "../../../../helpers/allStatuses";
import PaginatedEntityDropdown from "../../../styled/CommonComponents/PaginatedEntityDropdown";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "white"
    },
    backArrow: {
        fontSize: "30px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "20px",
        }
    },
    editBody: {
        width: "100%",
        height: "calc(100% - 50px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "30px 25px 100px",
        [theme.breakpoints.down('sm')]: {
            padding: "10px 10px 50px",
        }
    },
    topBar: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #ebeaea",
        paddingRight: "20px"
    },
    barLeft: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    barRight: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    },
    headerTitle: {
        fontSize: "20px",
        fontWeight: "550",
        marginLeft: "10px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
            fontWeight: "510",
            marginLeft: "0px",
        },
    },
    optionsCont: {
        padding: "5px"
    },
    typeSingleOptionSty: {
        padding: "4px 15px",
        fontSize: "16px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    },
    sectionTop: {
        borderBottom: "1px solid #d0cfcf",
        marginBottom: "15px",
        padding: "5px",
        "& h3": {
            fontSize: "21px",
            fontWeight: "600",
            color: "#193B56"
        },
        [theme.breakpoints.down('sm')]: {
            "& h3": {
                fontSize: "18px",
                fontWeight: "600",
            },
        }
    },
    basicDataCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    paperCont: {
        width: "100%",
        padding: "15px",
        marginBottom: "50px",
    },
    paperHeader: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "15px",
        "& h2": {
            fontSize: "18px",
            fontWeight: "600",
        }
    },
    lableValueCont: {
        width: "33.33%",
        padding: "10px",
        "& .inputCont": {
            width: "100%"
        }
    },
    addVendorSty: {
        width: "100%",
        color: "#aeacac",
        borderRadius: "0px",
        cursor: "pointer",
        padding: "9px 10px"
    },
    selectedUserSty: {
        width: "100%",
        padding: "2px 5px",
        cursor: "pointer",
        borderRadius: "0px",
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            marginLeft: "5px"
        },
        "& .MuiIconButton-root": {
            padding: "3px"
        },
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
        "& h3": {
            fontSize: "13px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "10px",
            fontWeight: "400",
            color: "gray"
        }
    },
    errorSty: {
        fontSize: "12px",
        fontWeight: "400",
        height: "4px",
        width: "100%",
        color: "red",
    },
    starSty: {
        color: "red"
    }
}));

const userOrgPalParam = [
    {
        entity: "User",
        scop: [
            {
                type: "Global",
                role: []
            }
        ]
    },
    {
        entity: "Organization",
        scop: [
            {
                type: "Global",
                role: []
            }
        ]
    },
    {
        entity: "User(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Group(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Organization(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    }
]

const BillOfQuantitiesEdit = ({
    opsOrder, setOpsOrder, wallet, projectProfileId, projectId, getOpsOrder,
    updateOpsOrderWithUpdate, updateOpsOrderWithOutUpdate, needToMakeDisable, 
    opsOrderStatus, setOpsOrderStatus, activeStage, needToMakeStatusDisable
}) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const { opsOrderId } = useParams();
    const { user } = useSelector((state) => state.auth);

    //helpers
    const [openVendorDrawer, setOpenVendorDrawer] = useState(false)
    const [loadingBool, setLoadingBool] = useState(false);
    const [validationObj, setValidationObj] = useState(null)
    const [billListError, setBillListError] = useState(false)
    const [alertObjValidation, setAlertObjValidation] = useState(null)
    const [needToReloadBillList, setNeedToReloadBillList] = useState(false)
    const [openApprovalFlow, setOpenApprovalFlow] = useState(false)
    const [openAction, setOpenAction] = useState(false)
    const [actionStatus, setActionStatus] = useState("")

    //values
    const [selectedProject, setSelectedProject] = useState(null)
    const [billListTotals, setBillListTotals] = useState({
        taxExclTotalAmount: 0,
        taxInclTotalAmount: 0,
        totalTaxAmount: 0,
    })
    const [deadline, setDeadline] = useState(new Date())
    const [arrival, setArrival] = useState(new Date())


    useEffect(() => {
        setOpsOrderStatus(opsOrder?.status || "Draft")
        if (opsOrder?.project?.profile?._id) {
            setSelectedProject({
                value: opsOrder?.project?.profile?._id,
                label: opsOrder?.project?.profile?.parent?.displayName,
                data: opsOrder?.project?.profile
            });
        } else {
            setSelectedProject(null);
        }
        setBillListTotals({
            taxExclTotalAmount: (parseFloat(opsOrder?.untaxedAmountVal || 0)).toFixed(2),
            taxInclTotalAmount: (parseFloat(opsOrder?.totalAmountVal || 0)).toFixed(2),
            totalTaxAmount: (parseFloat(opsOrder?.totalAmountVal || 0) - parseFloat(opsOrder?.untaxedAmountVal || 0)).toFixed(2)
        })
        setDeadline(opsOrder?.orderDeadline || new Date());
        setArrival(opsOrder?.expectedArrival || new Date());
    }, [opsOrder])

    useEffect(() => {
        setValidationObj(null)
    }, [selectedProject])

    // have to check
    const updateStatus = async (status) => {
        const basicDataSchema = yup.object().shape({
            project: yup
                .object()
                .shape({ value: yup.string().required("Please select an project") })
                .required("Please select an project")
                .typeError(({ path }) => "Please select an project"),

            expectedArrival: yup
                .date()
                .required("Please enter expected arrival date")
        });
        const formData = {
            project: selectedProject,
            expectedArrival: arrival,
            orderDeadline: deadline,
        }
        await basicDataSchema.validate(formData, { abortEarly: false })
            .then(async (validationRes) => {
                setActionStatus(status)
                setOpenAction(true)
            })
            .catch((err) => {
                console.log(err)
                let errorArr = err?.inner;
                let obj = {}
                errorArr.map((err) => {
                    obj[err?.path] = err?.message
                })
                setValidationObj(obj)
                setAlertObjValidation({
                    show: true,
                    message: "Please enter valid data in the below forms",
                    status: "error"
                })
            })
    }

    useDebounce(async () => {
        if (opsOrder?._id && (selectedProject?.data?.parent?._id != opsOrder?.project?._id)) {
            const obj = {
                project: selectedProject?.data?.parent?._id
            }
            setLoadingBool(true)
            await updateOpsOrderWithUpdate(opsOrder?._id, obj);
            setLoadingBool(false)
        }
    }, 2000, [selectedProject])

    useDebounce(async () => {
        if (opsOrder?._id && (billListTotals?.taxInclTotalAmount != opsOrder?.totalAmountVal || opsOrder?.expectedArrival != arrival || opsOrder?.orderDeadline != deadline)) {
            const obj = {
                untaxedAmountVal: parseFloat(billListTotals?.taxExclTotalAmount || 0),
                totalAmountVal: parseFloat(billListTotals?.taxInclTotalAmount || 0),
                expectedArrival: arrival,
                orderDeadline: deadline
            }
            setLoadingBool(true)
            await updateOpsOrderWithOutUpdate(opsOrder?._id, obj);
            setLoadingBool(false)
        }
    }, 2000, [billListTotals, deadline, arrival])


    return (
        <div className={classes.root}>
            <div className={classes.topBar} >
                <div className={classes.barLeft} >
                    <IconButton onClick={() => { history.goBack() }} >
                        <KeyboardBackspaceIcon className={classes.backArrow} />
                    </IconButton>
                    <h3 className={classes.headerTitle} >
                        {opsOrder?.name}
                    </h3>
                </div>
                <div className={classes.barRight} >
                    <Button
                        id="demo-customized-button"
                        variant="outlined"
                        size="small"
                        color="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => { setOpenApprovalFlow(true) }}
                        endIcon={<MdOutlineForkRight />}
                    >
                        Approval Flow
                    </Button>
                    <ApprovalAndActions
                        openApprovalFlow={openApprovalFlow}
                        setOpenApprovalFlow={setOpenApprovalFlow}
                        loadingBool={loadingBool}
                        setLoadingBool={setLoadingBool}
                        parent={opsOrder?._id}
                        parentModelName={"OpsOrder"}
                        requestId={opsOrder?.approvalRequest?._id}
                        needToMakeDisable={needToMakeStatusDisable}
                        activeStage={activeStage}
                    />
                    {needToMakeStatusDisable ? (
                        <Button
                            id="demo-customized-button"
                            variant="outlined"
                            size="small"
                            color="primary"
                            disabled
                            endIcon={<LockIcon />}
                        >
                            {opsOrderStatus ? opsOrderStatus : "STATUS"}
                        </Button>
                    ) : (<>
                        <MyPopOver
                            closeOnClick={true}
                            appearContent={
                                <Button
                                    id="demo-customized-button"
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    endIcon={<ArrowDropDown />}
                                >
                                    {opsOrderStatus ? opsOrderStatus : "STATUS"}
                                </Button>
                            }
                            showContent={<div className={classes.optionsCont} >
                                {allStatusOptions[opsOrder?.type][opsOrder?.status] && allStatusOptions[opsOrder?.type][opsOrder?.status].map((statusOption, i) => (
                                    <div
                                        key={i}
                                        className={classes.typeSingleOptionSty}
                                        onClick={() => { updateStatus(statusOption) }}
                                    >
                                        {statusOption}
                                    </div>
                                ))}
                            </div>}
                        />
                        <ApprovalActionDrawer
                            openAction={openAction}
                            setOpenAction={setOpenAction}
                            loadingBool={loadingBool}
                            setLoadingBool={setLoadingBool}
                            actionType={"takeAction"}
                            parent={opsOrder?._id}
                            parentModelName={"OpsOrder"}
                            statusField={"status"}
                            statusValue={actionStatus}
                            requestId={opsOrder?.approvalRequest?._id}
                            activeStage={activeStage}
                            actionCallBack={(type, curStatues) => {
                                getOpsOrder()
                            }}
                        />
                    </>)}
                </div>
            </div>
            <div style={{ width: "100%", height: "4px" }} >
                {loadingBool && (<LinearProgress />)}
            </div>
            <div className={classes.editBody} >
                <Paper className={classes.paperCont} elevation={2} >
                    <div className={classes.paperHeader} >
                        <h2>Basic Data</h2>
                        <h2></h2>
                    </div>
                    <div className={classes.basicDataCont} >
                        <div className={classes.lableValueCont} >
                            <InputWrapper
                                lable={"Owner"}
                                inputComp={
                                    <div className="inputCont" >
                                        <div className={classes.selectedUserSty} >
                                            <Avatar
                                                src={opsOrder?.projectParent?.displayPicture?.thumbUrl ? opsOrder?.projectParent?.displayPicture?.thumbUrl : opsOrder?.projectParent?.displayPicture?.url}
                                                alt={selectedProject?.data?.parent?.displayName}
                                            />
                                            <div style={{ marginLeft: "5px" }} >
                                                <h3>{opsOrder?.projectParent?.displayName}</h3>
                                                <p>{opsOrder?.projectParent?.username}</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                        <div className={classes.lableValueCont} >
                            <InputWrapper
                                lable={<>Project<span className={classes.starSty} >*</span></>}
                                inputComp={
                                    <div className="inputCont" >
                                        <PaginatedEntityDropdown
                                            value={selectedProject}
                                            onChange={(value) => {
                                                setSelectedProject(value);
                                            }}
                                            isMulti={false}
                                            entity={"Project"}
                                            curEntityId={projectId}
                                            givenProfileIdArr={[user?.profile]}
                                            palCreate={false}
                                            disabled={needToMakeDisable}
                                            noFilter={true}
                                            givenFilterOption={[
                                                {
                                                    option: "Network",
                                                    types: ["Project"]
                                                }
                                            ]}
                                        />
                                    </div>
                                }
                                validation={validationObj?.project ? validationObj?.project : validationObj?.["project.value"] ? validationObj?.["project.value"] : ""}
                            />
                        </div>
                    </div>
                </Paper>

                <Paper className={classes.paperCont} elevation={2} >
                    <div className={classes.paperHeader} >
                        <h2>Edit Items</h2>
                        <div></div>
                    </div>
                    {opsOrder?.project?._id && opsOrder?.billList && (
                        <BillItemsTable
                            billListId={opsOrder?.billList}
                            wallet={wallet}
                            projectProfileId={projectProfileId}
                            needToMakeDisable={needToMakeDisable}
                            setBillListError={setBillListError}
                            setBillListTotals={setBillListTotals}
                            billListTotals={billListTotals}
                            setNeedToReloadBillList={setNeedToReloadBillList}
                        />
                    )}
                </Paper>

                {opsOrder?._id && (
                    <Paper className={classes.paperCont} elevation={2} >
                        <div className={classes.paperHeader} >
                            <h2>Attachments</h2>
                            <h2></h2>
                        </div>
                        <AttachmentsHandler
                            parentData={opsOrder}
                            setParentData={setOpsOrder}
                            needToMakeDisable={needToMakeDisable}
                            parentModel={"OpsOrder"}
                            setLoadingBool={setLoadingBool}
                        />
                    </Paper>
                )}

                <NotifyAlert
                    alertObj={alertObjValidation}
                    setAlertObj={setAlertObjValidation}
                />
            </div>
        </div>
    );
};

export default BillOfQuantitiesEdit;