import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Checkbox, Button, TextField } from "@material-ui/core";
import { useTheme } from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MyPopOver from "../../../styled/CommonComponents/MyPopOver";
import TextFieldNumberWithoutAnyStyle from "../../../styled/CommonComponents/TextFieldNumberWithoutAnyStyle";
import AddIcon from '@material-ui/icons/Add';
import { LiaPlusSquareSolid, LiaWindowCloseSolid, LiaMinusSquareSolid } from "react-icons/lia";
import { itemTypes } from "../../../../helpers/procurement"
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from '@material-ui/core/Chip';
import UnitAutocomplete from "../../../styled/CommonComponents/UnitAutocomplete";
import MuiSelect2 from "../../../styled/CommonComponents/MuiSelect2";
import TaxDiscountCreateDrawer from "../../expenseAndBills/bills/BillList/TaxDiscountCreateDrawer";
import "./index.css"
import { useDebounce } from "react-use";
import { updateBillItemWithoutPopulate } from "../transaction/api";

const useStyles = makeStyles((theme) => ({
    singleItemTitleBtnContCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    optionsCont: {
        padding: "5px"
    },
    singleOption: {
        display: "flex",
        alignItems: "center",
        padding: "10px",
        cursor: "pointer",
        "& img": {
            width: "20px",
            height: "auto",
            marginRight: "10px"
        },
        "& p": {
            fontSize: "15px",
            fontWeight: "500"
        },
        "&:hover": {
            backgroundColor: "#e2e2e2"
        }
    },
    showChildIcon: {
        transform: "rotate(0deg)",
        transition: "all .3s ease-in-out",
    },
    hideChildIcon: {
        transform: "rotate(-90deg)",
        transition: "all .3s ease-in-out",
    },
    width200: {
        width: "200px",
        maxWidth: "200px"
    },
    width250: {
        width: "250px",
        maxWidth: "250px"
    },
    width120: {
        width: "120px",
        maxWidth: "120px"
    },
    width150: {
        width: "150px",
        maxWidth: "150px"
    },
    noBorder: {
        border: "none"
    },
    unitCol: {
        width: "100%",
        height: "3px",
        fontSize: "10px",
        fontWeight: "300",
        color: "red"
    },
    errorSty: {
        fontSize: "10px",
        fontWeight: "400",
        position: "absolute",
        bottom: "1px",
        right: "5px",
        width: "100%",
        color: "red"
    }
}));

const BillItemEdit = ({
    billitemData, paddingLeft, isCollapseChild, createNewBillItem, handleCheck,
    allSelectedIds, taxs, wallet, projectProfileId, setTaxs, billItems, setBillItems,
    needToMakeDisable
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const [openTaxAdd, setOpenTaxAdd] = useState(false)
    const [isCollapseSubChild, setCollapseSubChild] = useState(true)
    const [trStyle, setTrStyle] = useState({ display: "table-row", opacity: "1" })
    const [title, setTitle] = useState("")
    const [offering, setOffering] = useState(null)
    const [curQty, setCurQty] = useState(0)
    const [rate, setRate] = useState(0)
    const [itemType, setItemType] = useState(itemTypes[0])
    const [unit, setUnit] = useState()
    const [selectedTaxs, setSelectedTaxs] = useState([])
    const [taxExclAmount, setTaxExclAmount] = useState(0)
    const [totalTaxAmount, setTotalTaxAmount] = useState(0)
    const [validation, setValidation] = useState(null)

    useEffect(() => {
        setTitle(billitemData?.name || "");
        setOffering(billitemData?.offering);
        setCurQty(billitemData?.qTy)
        setRate(billitemData?.rate)
        setItemType(billitemData?.itemType || itemTypes[0])
        setUnit(billitemData?.unit)
        setSelectedTaxs(billitemData?.taxRelations || [])
    }, [])

    useEffect(() => {
        setCollapseSubChild(isCollapseChild)
    }, [isCollapseChild])

    useEffect(() => {
        let styleObj = {
            display: "table-row",
            opacity: "1"
        }
        if (billitemData?.isBillGroup) {
            styleObj.opacity = "0.8";
        }
        if (isCollapseChild) {
            styleObj.display = "none";
        }
        setTrStyle(styleObj)
    }, [billitemData?.isBillGroup, isCollapseChild])

    const calculateTotal = () => {
        let locQty = parseFloat(curQty || 0);
        let locRate = parseFloat(rate || 0);
        let taxs = [...selectedTaxs];
        let locTotalAmount = parseFloat(locQty * locRate).toFixed(2);
        let locTotalTaxAmount = 0;

        taxs.map((tax) => {
            if (tax?.type === "%") {
                let num = locTotalAmount * (parseFloat(tax?.percent || 0) / 100)
                locTotalTaxAmount = locTotalTaxAmount + num
            } else {
                locTotalTaxAmount = locTotalTaxAmount + parseFloat(tax?.amount || 0)
            }
        })

        setTaxExclAmount(locTotalAmount)
        setTotalTaxAmount(locTotalTaxAmount.toFixed(2))
    }

    //update by api call
    useDebounce(() => {
        calculateTotal()
        const updateObj = {
            _id: billitemData?._id,
            name: title,
            itemType: itemType,
            qTy: curQty,
            unit: unit,
            rate: rate,
            codeLibrary: wallet?.defaultLibrary?._id,
            taxRelations: selectedTaxs && selectedTaxs.length > 0 ? selectedTaxs.map((tax) => tax?._id) : []
        }
        if (offering?._id) {
            updateObj.offering = offering?._id;
        }
        if (offering?.category) {
            updateObj.category = offering?.category;
        }
        if (offering?.code) {
            updateObj.code = offering?.code;
        }
        if (offering?.expenseDescription) {
            updateObj.description = offering?.expenseDescription
        }
        updateBillItemWithoutPopulate(updateObj)
            .then((data) => {
                console.log(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, 2000, [title, offering, curQty, rate, itemType, unit, selectedTaxs])

    //update the local arr
    useDebounce(() => {
        const updatedBillItems = billItems.map((item) => {
            if (item?._id == billitemData?._id) {
                let updatedItem = {}
                let newObj = {}
                let isNotValidBool = false;
                if (!billitemData?.isBillGroup) {
                    if (!offering || !offering?._id) {
                        newObj.itemCol = "Please select an item";
                        isNotValidBool = true;
                    }
                    if (!itemType || itemType.length == 0) {
                        newObj.itemTypeCol = "Please select an item type";
                        isNotValidBool = true;
                    }
                    if (curQty == null || curQty == undefined) {
                        newObj.qtyCol = "Please enter valid quantity";
                        isNotValidBool = true;
                    }
                    if (rate == null || rate == undefined) {
                        newObj.rateCol = "Please enter valid rate";
                        isNotValidBool = true;
                    }
                    if (!unit || unit.length == 0) {
                        newObj.unitCol = "Please select an unit";
                        isNotValidBool = true;
                    }
                    updatedItem = {
                        ...item,
                        name: title,
                        itemType: itemType,
                        qTy: curQty,
                        unit: unit,
                        rate: rate,
                        codeLibrary: wallet?.defaultLibrary?._id,
                        taxRelations: selectedTaxs && selectedTaxs.length > 0 ? selectedTaxs : [],
                        offering: offering,
                        category: offering?.category,
                        code: offering?.code,
                        description: offering?.expenseDescription
                    }
                } else {
                    if (!title || title.length == 0) {
                        newObj.itemCol = "Please enter title";
                        isNotValidBool = true;
                    }
                    updatedItem = {
                        ...item
                    }
                }
                setValidation(newObj)
                updatedItem.isNotValid = isNotValidBool
                return updatedItem;
            } else {
                return item;
            }
        })
        setBillItems(updatedBillItems)
    }, 3000, [title, offering, curQty, rate, itemType, unit, selectedTaxs])

    function getLibraryId(library) {
        if (typeof library === 'object' && library !== null) {
            return library?._id;
        } else if (typeof library === 'string') {
            return library;
        }
        return null;
    }



    return (<>
        <tr className="workItemRow" style={trStyle} >
            {/* Item Type */}
            <td className={classes.width120} style={{ padding: "0px" }} >
                {billitemData?.isBillGroup ? <p style={{ textAlign: "left", fontSize: "14px", marginLeft: "10px" }} >Item Group</p> : (<>
                    <Autocomplete
                        id="free-solo-with-text-demo"
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        freeSolo
                        disableClearable
                        options={itemTypes}
                        disabled={needToMakeDisable}
                        value={itemType}
                        onChange={(event, value) => {
                            setItemType(value)
                        }}
                        title={itemType}
                        fullWidth
                        size="small"
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={"Select type"}
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    classes: { notchedOutline: classes.noBorder },
                                }}
                                size="small"
                            />
                        )}
                    />
                    <div className={classes.errorSty} >{validation?.itemTypeCol ? validation?.itemTypeCol : ""}</div>
                </>)}
            </td>
            {/* Item Group / Bill Item */}
            <td>
                <div className={classes.singleItemTitleBtnContCont} style={billitemData.children ? { paddingLeft: `${paddingLeft - 15}px` } : { paddingLeft: `${paddingLeft}px` }} >
                    <Checkbox
                        color="primary"
                        disabled={needToMakeDisable}
                        checked={allSelectedIds.includes(billitemData?._id)}
                        onChange={(e) => { handleCheck(billitemData?._id, e.target.checked) }}
                    />
                    <div className="singleItemTitleBtnCont" >
                        <span style={{ marginLeft: "-3px" }} >
                            {billitemData?.children && billitemData.children.length > 0 ? (<>
                                {isCollapseSubChild ? (
                                    <IconButton
                                        onClick={() => {
                                            setCollapseSubChild(!isCollapseSubChild)
                                        }} >
                                        <LiaPlusSquareSolid style={{ fontSize: "22px" }} />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() => {
                                            setCollapseSubChild(!isCollapseSubChild)
                                        }} >
                                        <LiaMinusSquareSolid style={{ fontSize: "22px" }} />
                                    </IconButton>
                                )}
                            </>) : (
                                <IconButton disabled >
                                    <LiaWindowCloseSolid style={{ fontSize: "22px" }} />
                                </IconButton>
                            )}
                        </span>
                        <img src={billitemData?.isBillGroup ? "https://mybuildcost.io/app/assets/icons/dropdown-icon/item-group-icon.svg" : "https://mybuildcost.io/app/assets/icons/dropdown-icon/work-item-icon.svg"} />
                        <div style={{ width: "100%" }} >
                            {billitemData?.isBillGroup ? (
                                <input
                                    type="text"
                                    placeholder="Enter group title"
                                    value={title}
                                    disabled={needToMakeDisable}
                                    onChange={(e) => { setTitle(e.target.value) }}
                                />
                            ) : (
                                <MuiSelect2
                                    selectOnFocus={true}
                                    clearOnBlur={true}
                                    handleHomeEndKeys={true}
                                    freeSolo={true}
                                    disableClearable={true}
                                    disabled={needToMakeDisable}
                                    fullWidth={true}
                                    value={offering}
                                    isAddnew={true}
                                    curProfileId={projectProfileId}
                                    library={wallet?.defaultLibrary}
                                    placeholder={"Select product/service"}
                                    onChange={(e, value) => {
                                        setOffering(value)
                                        setTitle(value?.name);
                                        setRate(value?.purchasePrice)
                                        setUnit(value?.purchaseUnit);
                                    }}
                                    getOptionLabel={(option) => option?.name || ""}
                                    isNoBorder={true}
                                    size={"small"}
                                    addonFilter={{ libraryId: getLibraryId(wallet?.defaultLibrary) }}
                                    apiUrl={"get/offering/dropdown/for-mui"}
                                />
                            )}
                        </div>
                        {(!needToMakeDisable && billitemData?.isBillGroup) ? (<>
                            <MyPopOver
                                closeOnClick={true}
                                appearContent={
                                    <Button className="singleItemBtnCont" >
                                        <AddIcon style={{ fontSize: "15px", color: "white" }} />
                                    </Button>
                                }
                                showContent={<div className={classes.optionsCont} >
                                    <div
                                        className={classes.singleOption}
                                        onClick={() => {
                                            createNewBillItem(true, billitemData?._id, billitemData?.numberOfChildren, billitemData?.ancestors || [])
                                        }}
                                    >
                                        <img src="https://mybuildcost.io/app/assets/icons/dropdown-icon/item-group-icon.svg" />
                                        <p>Item Group</p>
                                    </div>
                                    <div
                                        className={classes.singleOption}
                                        onClick={() => {
                                            createNewBillItem(false, billitemData?._id, billitemData?.numberOfChildren, billitemData?.ancestors || [])
                                        }}
                                    >
                                        <img src="https://mybuildcost.io/app/assets/icons/dropdown-icon/work-item-icon.svg" />
                                        <p>Bill Item</p>
                                    </div>
                                </div>}
                            />
                        </>) : null}
                    </div>
                </div>
                <div className={classes.errorSty} >{validation?.itemCol ? validation?.itemCol : ""}</div>
            </td>
            {/* Quantity */}
            <td className={classes.width150}  >
                {billitemData?.isBillGroup ? null : (
                    <TextFieldNumberWithoutAnyStyle
                        value={curQty}
                        fullWidth={true}
                        disabled={needToMakeDisable}
                        placeholder={"Enter Quantity"}
                        onChange={(e) => { setCurQty(e.target.value) }}
                    />
                )}
                <div className={classes.errorSty} >{validation?.qtyCol ? validation?.qtyCol : ""}</div>
            </td>
            {/* Rate/unit */}
            <td className={classes.width200} >
                {billitemData?.isBillGroup ? null : (
                    <TextFieldNumberWithoutAnyStyle
                        value={rate}
                        fullWidth={true}
                        disabled={needToMakeDisable}
                        placeholder={"Enter Rate/Unit"}
                        onChange={(e) => { setRate(e.target.value) }}
                    />
                )}
                <div className={classes.errorSty} >{validation?.rateCol ? validation?.rateCol : ""}</div>
            </td>
            {/* Unit */}
            <td className={classes.width200} style={{ padding: "0px" }} >
                {billitemData?.isBillGroup ? null : (
                    <UnitAutocomplete
                        fullWidth={true}
                        disabled={needToMakeDisable}
                        isNotShowFullName={true}
                        isNoBorder={true}
                        disableClearable={true}
                        value={unit}
                        onChange={(e, value) => {
                            setUnit(value);
                        }}
                    />
                )}
                <div className={classes.errorSty} >{validation?.unitCol ? validation?.unitCol : ""}</div>
            </td>
            {/* Taxes */}
            <td className={classes.width250} >
                {billitemData?.isBillGroup ? null : (<>
                    <Autocomplete
                        multiple
                        fullWidth
                        selectOnFocus
                        clearOnBlur
                        disableClearable
                        handleHomeEndKeys
                        disabled={needToMakeDisable}
                        freeSolo
                        id="free-solo-with-text-demo"
                        size="small"
                        value={selectedTaxs}
                        onChange={(event, newValue) => {
                            if (newValue[newValue.length - 1]?._id == "addNew") {
                                setOpenTaxAdd(true)
                            } else {
                                setSelectedTaxs([...newValue]);
                            }
                        }}
                        options={taxs}
                        getOptionLabel={(option) => option?.name}
                        renderTags={(value, getTagProps) => {
                            return value.map((option, index) => (
                                <Chip
                                    label={option?.name}
                                    size="small"
                                    {...getTagProps({ index })}
                                />
                            ))
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={"Select taxs"}
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    classes: { notchedOutline: classes.noBorder },
                                }}
                                size="small"
                            />
                        )}
                    />
                    <TaxDiscountCreateDrawer
                        open={openTaxAdd}
                        setOpen={setOpenTaxAdd}
                        walletId={wallet?._id}
                        profileId={wallet?.profile?._id ? wallet?.profile?._id : wallet?.profile}
                        libraryId={getLibraryId(wallet?.defaultLibrary)}
                        onSelect={(value) => {
                            setTaxs([...taxs, value])
                            setSelectedTaxs([...selectedTaxs, value])
                        }}
                        createType={"Tax"}
                    />
                </>)}
            </td>
            {/* Taxes excl */}
            <td className={classes.width150} >{billitemData?.isBillGroup ? parseFloat(billitemData?.totalRateAmount).toFixed(2) : taxExclAmount}</td>
            {/* Total Amount Tax  */}
            <td className={classes.width150} >{billitemData?.isBillGroup ? parseFloat(billitemData?.totalTaxAmount).toFixed(2) : totalTaxAmount}</td>
            {/* Taxes incl */}
            <td className={classes.width200} >{billitemData?.isBillGroup ? parseFloat(billitemData?.allTotalAmount).toFixed(2) : (parseFloat(taxExclAmount) + parseFloat(totalTaxAmount)).toFixed(2)}</td>
        </tr>
        {
            billitemData.children && billitemData.children.map(child => (
                <BillItemEdit
                    key={child?._id}
                    billItems={billItems}
                    setBillItems={setBillItems}
                    billitemData={child}
                    createNewBillItem={createNewBillItem}
                    paddingLeft={paddingLeft + 15}
                    isCollapseChild={isCollapseSubChild}
                    handleCheck={handleCheck}
                    taxs={taxs}
                    needToMakeDisable={needToMakeDisable}
                    setTaxs={setTaxs}
                    projectProfileId={projectProfileId}
                    wallet={wallet}
                    allSelectedIds={allSelectedIds}
                />
            ))
        }
    </>);
};

export default BillItemEdit;