// GRNCertificatesTable.js

import React from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Button,
  Avatar
} from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

const useStyles = makeStyles((theme) => ({
   
    container: {
      width: "100%",
      "& tr": {
        height: "45px",
      },
      "&.MuiTableCell-root": {
        outline: "1px solid #ececec",
        border: "none",
      },
      "& table": {
        outline: "1px solid #d2d2d2",
        border: "1px solid #ececec",
        borderCollapse: "collapse",
      },
      "& th": {
        outline: "1px solid #d2d2d2",
        border: "none",
        borderCollapse: "collapse",
        fontSize: "12px",
        fontWeight: "550",
        padding: "10px",
      },
      "& td": {
        outline: "1px solid #d2d2d2",
        border: "none",
        borderCollapse: "collapse",
        fontSize: "12px",
        fontWeight: "500",
        padding: "0px 10px",
      },
    },
    selectedUserOrgSty: {
      display: "flex",
      alignItems: "center",
      "& .MuiSvgIcon-root": {
        fontSize: "20px",
        marginLeft: "5px",
      },
      "& .MuiIconButton-root": {
        padding: "3px",
      },
      "& .MuiAvatar-root": {
        width: "30px",
        height: "30px",
      },
      "& h3": {
        fontSize: "13px",
        fontWeight: "500",
      },
      "& p": {
        fontSize: "10px",
        fontWeight: "400",
        color: "gray",
        textAlign: "left",
      },
    },

  }));

;

const GRNCertificatesTable = ({ grnData }) => {
const classes = useStyles()
const history = useHistory();

  const navigateToGRN = (grnId) => {
    history.push(`/procurements/ops-order/${grnId}/edit`);
  };
  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">GRN No.</TableCell>
            <TableCell align="center">Amount</TableCell>
            <TableCell align="center">Created By</TableCell>
            <TableCell align="center">Created At</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="right" style={{ width: "240px" }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {grnData.map((grn) => (
            <TableRow key={grn._id}>
              <TableCell align="left">{grn.name}</TableCell>
              <TableCell align="center">
                {numberWithCommas(parseFloat(grn.totalAmountVal || 0))}
              </TableCell>
              <TableCell align="center">
                <div className={classes.selectedUserOrgSty}>
                  <Avatar
                    src={grn.user?.displayPicture?.thumbUrl || grn.user?.displayPicture?.url}
                    alt={grn.user?.displayName}
                  />
                  <div style={{ marginLeft: "5px" }}>
                    <h3>{grn.user?.displayName}</h3>
                    <p>{grn.user?.username}</p>
                  </div>
                </div>
              </TableCell>
              <TableCell align="center">
                {moment(grn.created).format("DD/MMM/YYYY")}
              </TableCell>
              <TableCell align="center">
                {grn.status}
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => 
                    navigateToGRN(grn._id)
                  }
                >
                  View Details
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GRNCertificatesTable;