import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../styled/agGrid.css";
import { getOpsOrderById } from "../../budgetCostAnalysis/api.call";
import WorkorderEdit from "./Workorder/WorkorderEdit";
import MaterialRequestEdit from "./materialRequest/Material.Request.edit";
import BillOfQuantitiesEdit from "./billOfQuantities/Bill.Of.Quantities.edit";
import PurchaseOrderEdit from "./purchaseOrder/Purchase.Order.edit";
import { getProjectsByOwner, updateOpsOrderWithPopulate, updateOpsOrderWithoutPopulate } from "./api.call";
import GRNEdit from "./GRN/GRN.Edit";

const useStyles = makeStyles((theme) => ({

}));

const OpsOrderEdit = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { opsOrderId } = useParams();

    const { user } = useSelector((state) => state.auth);

    const [projectProfileId, setProjectProfileId] = useState();
    const [opsOrder, setOpsOrder] = useState();
    const [projectId, setProjectId] = useState(null)
    const [wallet, setWallet] = useState()
    const [certificates, setCertificates] = useState([])
    const [needToMakeDisable, setNeedToMakeDisable] = useState(true)
    const [opsOrderStatus, setOpsOrderStatus] = useState("");
    const [activeStage, setActiveStage] = useState(null)
    const [needToMakeStatusDisable, setNeedToMakeStatusDisable] = useState(true)

    const updateOpsOrderWithOutUpdate = async (id, newObj) => {
        await updateOpsOrderWithoutPopulate({ id, newObj })
            .then((data) => {
                console.log(data);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const updateOpsOrderWithUpdate = async (_id, updateObj) => {
        await updateOpsOrderWithPopulate({
            _id,
            updateObj
        })
            .then((data) => {
                setOpsOrder(data);
                setProjectProfileId(data?.project?.profile?._id);
                setWallet(data?.project?.wallet)
                setProjectId(data?.project?._id)
                let locCertificates = data?.allocations ? data.allocations.map((s) => s?.identifier) : []
                setCertificates(locCertificates)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getOpsOrder = async () => {
        await getOpsOrderById({ opsOrderId: opsOrderId })
            .then(async (data) => {
                console.log(data)
                setOpsOrder(data);
                setProjectProfileId(data?.project?.profile?._id);
                setWallet(data?.project?.wallet)
                setProjectId(data?.project?._id)
                setOpsOrderStatus(data?.status || "Draft")
                let locCertificates = data?.allocations ? data.allocations.map((s) => s?.identifier) : []
                setCertificates(locCertificates)
            })
            .catch((err) => {
                console.log(err)
                dispatch({
                    type: "AddApiAlert",
                    payload: {
                        success: false,
                        message: "Unable to fetch ops order",
                    },
                });
            })
    };

    useEffect(() => {
        if (opsOrderId) {
            getOpsOrder();
        }
    }, [opsOrderId]);

    useEffect(() => {
        let locActiveStage = opsOrder?.approvalRequest?.activeStage || null
        setActiveStage(locActiveStage)
        let canEdit = false
        if (locActiveStage?.approvalType === "anybody") {
            canEdit = true
        } else {
            let approvers = locActiveStage?.approvers || [];
            console.log(approvers)
            for (let i = 0; i < approvers.length; i++) {
                if (approvers[i].profile == user?.profile && !approvers[i]?.isApproved && !approvers[i]?.isRejected) {
                    canEdit = true;
                    break;
                }
            }
        }
        setNeedToMakeStatusDisable(!canEdit)
        if (locActiveStage?.canEditMainData && canEdit) {
            setNeedToMakeDisable(false)
        } else {
            setNeedToMakeDisable(true)
        }
    }, [opsOrder?.approvalRequest?.activeStage])

    let EditView = null;

    switch (opsOrder?.type) {
        case "Material Request":
            EditView = (
                <MaterialRequestEdit
                    opsOrder={opsOrder}
                    setOpsOrder={setOpsOrder}
                    wallet={wallet}
                    projectProfileId={projectProfileId}
                    projectId={projectId}
                    activeStage={activeStage}
                    updateOpsOrderWithUpdate={updateOpsOrderWithUpdate}
                    updateOpsOrderWithOutUpdate={updateOpsOrderWithOutUpdate}
                    needToMakeDisable={needToMakeDisable}
                    setNeedToMakeDisable={setNeedToMakeDisable}
                    opsOrderStatus={opsOrderStatus}
                    setOpsOrderStatus={setOpsOrderStatus}
                    getOpsOrder={getOpsOrder}
                    needToMakeStatusDisable={needToMakeStatusDisable}
                />
            );
            break;

        case "Bill Of Quantities":
            EditView = (
                <BillOfQuantitiesEdit
                    opsOrder={opsOrder}
                    setOpsOrder={setOpsOrder}
                    wallet={wallet}
                    projectProfileId={projectProfileId}
                    projectId={projectId}
                    activeStage={activeStage}
                    updateOpsOrderWithUpdate={updateOpsOrderWithUpdate}
                    updateOpsOrderWithOutUpdate={updateOpsOrderWithOutUpdate}
                    needToMakeDisable={needToMakeDisable}
                    setNeedToMakeDisable={setNeedToMakeDisable}
                    opsOrderStatus={opsOrderStatus}
                    setOpsOrderStatus={setOpsOrderStatus}
                    getOpsOrder={getOpsOrder}
                    needToMakeStatusDisable={needToMakeStatusDisable}
                />
            );
            break;

        case "Purchase Order":
            EditView = (
                <PurchaseOrderEdit
                    opsOrder={opsOrder}
                    setOpsOrder={setOpsOrder}
                    wallet={wallet}
                    projectProfileId={projectProfileId}
                    projectId={projectId}
                    activeStage={activeStage}
                    certificates={certificates}
                    updateOpsOrderWithUpdate={updateOpsOrderWithUpdate}
                    updateOpsOrderWithOutUpdate={updateOpsOrderWithOutUpdate}
                    needToMakeDisable={needToMakeDisable}
                    setNeedToMakeDisable={setNeedToMakeDisable}
                    opsOrderStatus={opsOrderStatus}
                    setOpsOrderStatus={setOpsOrderStatus}
                    getOpsOrder={getOpsOrder}
                    needToMakeStatusDisable={needToMakeStatusDisable}
                />
            );
            break;

        case "Work Order":
            EditView = (
                <WorkorderEdit
                    opsOrder={opsOrder}
                    setOpsOrder={setOpsOrder}
                    wallet={wallet}
                    projectProfileId={projectProfileId}
                    projectId={projectId}
                    activeStage={activeStage}
                    certificates={certificates}
                    updateOpsOrderWithUpdate={updateOpsOrderWithUpdate}
                    updateOpsOrderWithOutUpdate={updateOpsOrderWithOutUpdate}
                    needToMakeDisable={needToMakeDisable}
                    setNeedToMakeDisable={setNeedToMakeDisable}
                    opsOrderStatus={opsOrderStatus}
                    setOpsOrderStatus={setOpsOrderStatus}
                    getOpsOrder={getOpsOrder}
                    needToMakeStatusDisable={needToMakeStatusDisable}
                />
            );
            break;

        case "GRN":
            EditView = (
                <GRNEdit
                    opsOrder={opsOrder}
                    setOpsOrder={setOpsOrder}
                    wallet={wallet}
                    projectProfileId={projectProfileId}
                    projectId={projectId}
                    activeStage={activeStage}
                    updateOpsOrderWithUpdate={updateOpsOrderWithUpdate}
                    updateOpsOrderWithOutUpdate={updateOpsOrderWithOutUpdate}
                    needToMakeDisable={needToMakeDisable}
                    setNeedToMakeDisable={setNeedToMakeDisable}
                    opsOrderStatus={opsOrderStatus}
                    setOpsOrderStatus={setOpsOrderStatus}
                    getOpsOrder={getOpsOrder}
                    needToMakeStatusDisable={needToMakeStatusDisable}
                />
            );
            break;
    }
    return EditView;
};

export default OpsOrderEdit;