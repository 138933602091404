import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, Paper, Avatar, InputAdornment, Grid,Card,CardContent,Box,Typography, Chip } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import LinearProgress from "@material-ui/core/LinearProgress";
import BudgetUpdate from "../../../budgetCostAnalysis/BudgetUpdate";
import { TextField } from "@mui/material";
import { ArrowDropDown, Description } from "@material-ui/icons";
import MyPopOver from "../../../styled/CommonComponents/MyPopOver";
import BillItemsTable from "../../../finance2o/commonComponent/Bill.Item.Table/Bill.Items.Table";
import { mainRoles } from "../../../../helpers/contactHelper";
import TextFieldNumberFormated from "../../../styled/CommonComponents/TextFieldNumberFormated";
import * as yup from "yup";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { FaPercent } from "react-icons/fa6";
import { MdOutlineForkRight } from "react-icons/md";
import DateFnsUtils from "@date-io/date-fns";
import { useDebounce } from "react-use";
import NotifyAlert from "../../../styled/CommonComponents/NotifyAlert";
import "../../../styled/agGrid.css";
import CloneBillItems from "../Clone.Bill.Items";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import config from "../../../../config/index";
import { setAuthCookieData } from "../../../../helpers/cookie.helper";
import InputWrapper from "../../../styled/CommonComponents/InputWarpper";
import AttachmentsHandler from "../../../styled/CommonComponents/Attachments.Handler";
import ExportPDF from "../../../styled/generic/ExportPDF";
import ApprovalAndActions from "../../../approval/ApprovalAndActions";
import ApprovalActionDrawer from "../../../approval/ApprovalActionDrawer";
import LockIcon from "@material-ui/icons/Lock";
import { allStatusOptions } from "../../../../helpers/allStatuses";
import PaginatedEntityDropdown from "../../../styled/CommonComponents/PaginatedEntityDropdown";
import LinkedDocsDrawer from "../Workorder/LinkedDocsDrawer";
import Api from "../../../../helpers/Api";
import GRNCertificatesTable from "./GRNCertificatesTable";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "white",
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  editBody: {
    width: "100%",
    height: "calc(100% - 50px)",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "30px 25px 100px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 50px",
    },
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
    paddingRight: "20px",
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
  optionsCont: {
    padding: "5px",
  },
  typeSingleOptionSty: {
    padding: "4px 15px",
    fontSize: "16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
  sectionTop: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "15px",
    padding: "5px",
    "& h3": {
      fontSize: "21px",
      fontWeight: "600",
      color: "#193B56",
    },
    [theme.breakpoints.down("sm")]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
  },
  basicDataCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  paperCont: {
    width: "100%",
    padding: "15px",
    marginBottom: "50px",
  },
  paperHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
    "& h2": {
      fontSize: "18px",
      fontWeight: "600",
    },
  },
  lableValueCont: {
    width: "33.33%",
    padding: "10px",
    "& .inputCont": {
      width: "100%",
    },
  },
  addVendorSty: {
    width: "100%",
    color: "#aeacac",
    borderRadius: "0px",
    cursor: "pointer",
    padding: "9px 10px",
  },
  selectedUserSty: {
    width: "100%",
    padding: "2px 5px",
    cursor: "pointer",
    borderRadius: "0px",
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
      marginLeft: "5px",
    },
    "& .MuiIconButton-root": {
      padding: "3px",
    },
    "& .MuiAvatar-root": {
      width: "30px",
      height: "30px",
    },
    "& h3": {
      fontSize: "13px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "10px",
      fontWeight: "400",
      color: "gray",
    },
  },
  errorSty: {
    fontSize: "12px",
    fontWeight: "400",
    height: "4px",
    width: "100%",
    color: "red",
  },
  starSty: {
    color: "red",
  },
  container: {
    width: "100%",
    "& tr": {
      height: "45px",
    },
    "&.MuiTableCell-root": {
      outline: "1px solid #ececec",
      border: "none",
    },
    "& table": {
      outline: "1px solid #d2d2d2",
      border: "1px solid #ececec",
      borderCollapse: "collapse",
    },
    "& th": {
      outline: "1px solid #d2d2d2",
      border: "none",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "550",
      padding: "10px",
    },
    "& td": {
      outline: "1px solid #d2d2d2",
      border: "none",
      borderCollapse: "collapse",
      fontSize: "12px",
      fontWeight: "500",
      padding: "0px 10px",
    },
  },
  selectedUserOrgSty: {
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
      marginLeft: "5px",
    },
    "& .MuiIconButton-root": {
      padding: "3px",
    },
    "& .MuiAvatar-root": {
      width: "30px",
      height: "30px",
    },
    "& h3": {
      fontSize: "13px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "10px",
      fontWeight: "400",
      color: "gray",
      textAlign: "left",
    },
  },
  card: {
    height: '90%',
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 0.3s',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: theme.shadows[4],
    },
  },
  cardContent: {
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  iconText: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      marginRight: theme.spacing(1),
    },
  },
  boldText: {
    fontWeight: 'bold',
  },
}));

const userOrgPalParam = [
  {
    entity: "User",
    scop: [
      {
        type: "Global",
        role: [],
      },
    ],
  },
  {
    entity: "Organization",
    scop: [
      {
        type: "Global",
        role: [],
      },
    ],
  },
  {
    entity: "User(Pal)",
    scop: [
      {
        type: "Contact",
        role: [...mainRoles],
      },
    ],
  },
  {
    entity: "Group(Pal)",
    scop: [
      {
        type: "Contact",
        role: [...mainRoles],
      },
    ],
  },
  {
    entity: "Organization(Pal)",
    scop: [
      {
        type: "Contact",
        role: [...mainRoles],
      },
    ],
  },
];

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const PurchaseOrderEdit = ({
  opsOrder, setOpsOrder, wallet, projectProfileId, projectId,
  certificates, updateOpsOrderWithUpdate, updateOpsOrderWithOutUpdate,
  needToMakeDisable, getOpsOrder, opsOrderStatus, setOpsOrderStatus,
  activeStage, needToMakeStatusDisable
}) => {
  const isDev = config.mode == "dev";
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { opsOrderId } = useParams();

  const { user } = useSelector((state) => state.auth);

  //helpers
  const [loadingBool, setLoadingBool] = useState(false);
  const [openVendorDrawer, setOpenVendorDrawer] = useState(false);
  const [validationObj, setValidationObj] = useState(null);
  const [billListError, setBillListError] = useState(false);
  const [alertObjValidation, setAlertObjValidation] = useState(null);
  const [needToReloadBillList, setNeedToReloadBillList] = useState(false);
  const [openApprovalFlow, setOpenApprovalFlow] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [actionStatus, setActionStatus] = useState("");
  const [openLinkedDocsDrawer, setOpenLinkedDocsDrawer] = useState(false);
  const [linkedDocsDetails, setLinkedDocsDetails] = useState([]);
  const [linkedDocs, setLinkedDocs] = useState(opsOrder.linkedDocs || []);
  const [linkedGRNs, setLinkedGRNs] = useState([]);
  const [totalGRNAmount, setTotalGRNAmount] = useState(0);


  //values
  const [selectedBudgetId, setSelectedBudgetId] = useState([]);
  const [vendor, setVendor] = useState(null);
  const [advanceAmount, setAdvanceAmount] = useState(0);
  const [retantionPercentage, setRetantionPercentage] = useState(0);
  const [selectedProject, setSelectedProject] = useState(null);
  const [billListTotals, setBillListTotals] = useState({
    taxExclTotalAmount: 0,
    taxInclTotalAmount: 0,
    totalTaxAmount: 0,
  });
  const [budgetUpdateStatus, setBudgetUpdateStatus] = useState(null);
  const [deadline, setDeadline] = useState(new Date());
  const [arrival, setArrival] = useState(new Date());
  const [totalCFAmount, setTotalCFAmount] = useState(0);

  const fetchLinkedDocsDetails = async (docIds) => {
    try {
      const response = await Api.post('/procurement/ops/get-linked-docs', {
        linkedDocIds: docIds
      });
      console.log("The linked docs response:" , response);
      setLinkedDocsDetails(response.linkedDocs);
    } catch (error) {
      console.error('Error fetching linked documents details:', error);
    }
  };

  useEffect(() => {
    if (opsOrder.linkedDocs && opsOrder.linkedDocs.length > 0) {
      fetchLinkedDocsDetails(opsOrder.linkedDocs);
    } else {
      setLinkedDocsDetails([]);
    }
  }, [opsOrder.linkedDocs]);

  const handleLinkedDocsUpdate = (newLinkedDocs) => {
    setOpsOrder(prevOpsOrder => ({
      ...prevOpsOrder,
      linkedDocs: newLinkedDocs
    }));
    fetchLinkedDocsDetails(newLinkedDocs);
  };


  useEffect(() => {
    let locAmount = 0;
    certificates.map((cf) => {
      locAmount = locAmount + parseFloat(cf?.netPayment || 0);
    });
    setTotalCFAmount(locAmount);
  }, [certificates]);

  useEffect(() => {
    if (opsOrderStatus === "Draft") {
      setBudgetUpdateStatus("Draft");
    } else if (
      opsOrderStatus === "Submitted" ||
      opsOrderStatus === "Under Review" ||
      opsOrderStatus === "Evaluated"
    ) {
      setBudgetUpdateStatus("Submitted");
    } else if (opsOrderStatus !== "Rejected") {
      setBudgetUpdateStatus("Approved");
    } else {
      setBudgetUpdateStatus("Draft");
    }
  }, [opsOrderStatus]);

  useEffect(() => {
    setSelectedBudgetId(opsOrder?.selectedBudgetIds || []);
    setOpsOrderStatus(opsOrder?.status || "Draft");
    if (opsOrder?.project?.profile?._id) {
      setSelectedProject({
        value: opsOrder?.project?.profile?._id,
        label: opsOrder?.project?.profile?.parent?.displayName,
        data: opsOrder?.project?.profile
      });
    } else {
      setSelectedProject(null);
    }
    if (opsOrder?.vendor?._id) {
      setVendor({
        value: opsOrder?.vendor?._id,
        label: opsOrder?.vendor?.parent?.displayName,
        data: opsOrder?.vendor
      });
    } else {
      setVendor(null);
    }
    setDeadline(opsOrder?.orderDeadline || new Date());
    setArrival(opsOrder?.expectedArrival || new Date());
    setRetantionPercentage(opsOrder?.retentionPercentage || 0);
    setAdvanceAmount(parseFloat(opsOrder?.advanceAmount || 0));
    setBillListTotals({
      taxExclTotalAmount: parseFloat(opsOrder?.untaxedAmountVal || 0).toFixed(
        2
      ),
      taxInclTotalAmount: parseFloat(opsOrder?.totalAmountVal || 0).toFixed(2),
      totalTaxAmount: (
        parseFloat(opsOrder?.totalAmountVal || 0) -
        parseFloat(opsOrder?.untaxedAmountVal || 0)
      ).toFixed(2),
    });
  }, [opsOrder]);

  useEffect(() => {
    if (opsOrder._id && opsOrder.type === "Purchase Order") {
      fetchLinkedGRNs(opsOrder._id);
    }
  }, [opsOrder._id, opsOrder.type]);

  const fetchLinkedGRNs = async (opsOrderId) => {
    try {
      const response = await Api.post('/procurement/ops/get-linked-grns', {
        opsOrderId: opsOrderId
      });
      console.log("The linked grns:", response.linkedGRNs);
      setLinkedGRNs(response.linkedGRNs);
      const total = response.linkedGRNs.reduce((sum, grn) => sum + (grn.totalAmountVal || 0), 0);
      setTotalGRNAmount(total);
    } catch (error) {
      console.error('Error fetching linked GRNs:', error);
    }
  };

  const addNewSelectedItems = async (items) => {
    const updatedOpsOrder = {
      ...opsOrder,
      selectedBudgetIds: items,
    };
    setOpsOrder(updatedOpsOrder);
    await updateOpsOrderWithOutUpdate(opsOrder?._id, {
      selectedBudgetIds: items,
    });
  };

  useEffect(() => {
    setValidationObj(null);
  }, [vendor, selectedProject, retantionPercentage, advanceAmount]);

  // have to check
  const updateStatus = async (status) => {
    const basicDataSchema = yup.object().shape({
      vendor: yup
        .object()
        .shape({ value: yup.string().required("Please select an vendor") })
        .required("Please select an vendor")
        .typeError(({ path }) => "Please select an vendor"),
      project: yup
        .object()
        .shape({ value: yup.string().required("Please select an project") })
        .required("Please select an project")
        .typeError(({ path }) => "Please select an project"),
      ["retantion percentage"]: yup
        .number()
        .min(0)
        .max(100)
        .required("Retantion percentage is required"),
      ["advance amount"]: yup
        .number()
        .min(0)
        .max(parseFloat(billListTotals?.taxInclTotalAmount || 0))
        .required("Event type is required"),
      expectedArrival: yup
        .date()
        .required("Please enter expected arrival date"),
      orderDeadline: yup
        .date()
        .required("Please enter expected arrival date")
        .test(
          "is-before-expected-arrival",
          "Order Deadline must be earlier than Expected Arrival",
          function (value) {
            const expectedArrival = this.parent.expectedArrival;
            if (!expectedArrival || !value) {
              return true;
            }
            return value < expectedArrival;
          }
        ),
    });
    const formData = {
      vendor: vendor,
      project: selectedProject,
      expectedArrival: arrival,
      orderDeadline: deadline,
      ["retantion percentage"]: retantionPercentage,
      ["advance amount"]: advanceAmount,
    };
    await basicDataSchema
      .validate(formData, { abortEarly: false })
      .then(async (validationRes) => {
        setActionStatus(status);
        setOpenAction(true);

        // if (!billListError) {
        //   setOpsOrderStatus(opsOrder?.status || "Draft");
        //   const obj = {
        //     status: status,
        //   };
        //   setLoadingBool(true);
        //   await updateOpsOrderWithUpdate(opsOrder?._id, obj);
        //   setLoadingBool(false);
        //   setAlertObjValidation({
        //     show: true,
        //     message: "Status successfully updated",
        //     status: "success",
        //   });
        // } else {
        //   setAlertObjValidation({
        //     show: true,
        //     message: "Please enter valid data in the below forms",
        //     status: "error",
        //   });
        // }
      })
      .catch((err) => {
        console.log(err);
        let errorArr = err?.inner;
        let obj = {};
        errorArr.map((err) => {
          obj[err?.path] = err?.message;
        });
        setValidationObj(obj);
        setAlertObjValidation({
          show: true,
          message: "Please enter valid data in the below forms",
          status: "error",
        });
      });
  };

  useDebounce(
    async () => {
      if (opsOrder?._id && (vendor?.value != opsOrder?.vendor?._id || selectedProject?.data?.parent?._id != opsOrder?.project?._id)) {
        const obj = {
          vendor: vendor?.value,
          project: selectedProject?.data?.parent?._id,
        };
        setLoadingBool(true);
        await updateOpsOrderWithUpdate(opsOrder?._id, obj);
        setLoadingBool(false);
      }
    },
    2000,
    [vendor, selectedProject]
  );

  useDebounce(
    async () => {
      if (
        opsOrder?._id &&
        (billListTotals?.taxInclTotalAmount != opsOrder?.totalAmountVal ||
          opsOrder?.expectedArrival != arrival ||
          opsOrder?.orderDeadline != deadline ||
          retantionPercentage != opsOrder?.retentionPercentage ||
          advanceAmount != opsOrder?.advanceAmount)
      ) {
        const obj = {
          untaxedAmountVal: parseFloat(billListTotals?.taxExclTotalAmount || 0),
          totalAmountVal: parseFloat(billListTotals?.taxInclTotalAmount || 0),
          retentionPercentage: parseFloat(retantionPercentage || 0),
          advanceAmount: parseFloat(advanceAmount || 0),
          expectedArrival: arrival,
          orderDeadline: deadline,
        };
        setLoadingBool(true);
        await updateOpsOrderWithOutUpdate(opsOrder?._id, obj);
        setLoadingBool(false);
      }
    },
    2000,
    [advanceAmount, retantionPercentage, billListTotals, deadline, arrival]
  );

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <div className={classes.barLeft}>
          <IconButton
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardBackspaceIcon className={classes.backArrow} />
          </IconButton>
          <h3 className={classes.headerTitle}>{opsOrder?.name}</h3>
        </div>
        <div className={classes.barRight}>
          <ExportPDF
            apiUrl="/procurement/ops/order/pdf"
            fileName="order"
            apiBody={{
              opsOrderId: opsOrderId,
            }}
            enableSignatures={true}
            enableTermsAndConditions={true}
            libraryId={opsOrder?.project?.wallet?.defaultLibrary}
            profileId={opsOrder?.project?.profile}
          />
          <BudgetUpdate
            selectedBudgetId={selectedBudgetId}
            setSelectedBudgetId={setSelectedBudgetId}
            addNewSelectedItems={addNewSelectedItems}
            budgetUpdateStatus={budgetUpdateStatus}
            workspaceType={"OpsOrder"}
            workspaceId={opsOrderId}
            projectProfileId={opsOrder?.project?.profile?._id}
            projectId={opsOrder?.project?._id}
            projectParentId={opsOrder?.projectParent}
            projectParentModel={opsOrder?.projectParentModel}
            projectBlockId={opsOrder?.projectBlock?._id}
            parentTotalAmount={billListTotals?.taxInclTotalAmount || 0}
          />
          <div style={{ width: "10px" }}></div>
          <Button
            id="demo-customized-button"
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
              setOpenApprovalFlow(true);
            }}
            endIcon={<MdOutlineForkRight />}
          >
            Approval Flow
          </Button>
          <ApprovalAndActions
            openApprovalFlow={openApprovalFlow}
            setOpenApprovalFlow={setOpenApprovalFlow}
            loadingBool={loadingBool}
            setLoadingBool={setLoadingBool}
            parent={opsOrder?._id}
            parentModelName={"OpsOrder"}
            requestId={opsOrder?.approvalRequest?._id}
            needToMakeDisable={needToMakeStatusDisable}
            activeStage={activeStage}
          />
          <div style={{ width: "10px" }}></div>
          {needToMakeStatusDisable ? (
            <Button
              id="demo-customized-button"
              variant="outlined"
              size="small"
              color="primary"
              disabled
              endIcon={<LockIcon />}
            >
              {opsOrderStatus ? opsOrderStatus : "STATUS"}
            </Button>
          ) : (
            <>
              <MyPopOver
                closeOnClick={true}
                appearContent={
                  <Button
                    id="demo-customized-button"
                    variant="outlined"
                    size="small"
                    color="primary"
                    endIcon={<ArrowDropDown />}
                  >
                    {opsOrderStatus ? opsOrderStatus : "STATUS"}
                  </Button>
                }
                showContent={
                  <div className={classes.optionsCont}>
                    {allStatusOptions[opsOrder?.type][opsOrder?.status] &&
                      allStatusOptions[opsOrder?.type][opsOrder?.status].map(
                        (statusOption, i) => (
                          <div
                            key={i}
                            className={classes.typeSingleOptionSty}
                            onClick={() => {
                              updateStatus(statusOption);
                            }}
                          >
                            {statusOption}
                          </div>
                        )
                      )}
                  </div>
                }
              />
              <ApprovalActionDrawer
                openAction={openAction}
                setOpenAction={setOpenAction}
                loadingBool={loadingBool}
                setLoadingBool={setLoadingBool}
                actionType={"takeAction"}
                parent={opsOrder?._id}
                parentModelName={"OpsOrder"}
                statusField={"status"}
                statusValue={actionStatus}
                requestId={opsOrder?.approvalRequest?._id}
                activeStage={activeStage}
                actionCallBack={(type, curStatues) => {
                  getOpsOrder();
                }}
              />
            </>
          )}
        </div>
      </div>
      <div style={{ width: "100%", height: "4px" }}>
        {loadingBool && <LinearProgress />}
      </div>
      <div className={classes.editBody}>
        <Paper className={classes.paperCont} elevation={2}>
          <div className={classes.paperHeader}>
            <h2>Purchase Order Data</h2>
            <h2></h2>
          </div>
          <div className={classes.basicDataCont}>
            <div className={classes.lableValueCont}>
              <InputWrapper
                lable={<>Vendor<span className={classes.starSty}>*</span></>}
                inputComp={
                  <div className="inputCont">
                    <PaginatedEntityDropdown
                      value={vendor}
                      onChange={(value) => {
                        console.log(value);
                        setVendor(value);
                      }}
                      isMulti={false}
                      entity={"Project"}
                      disabled={needToMakeDisable}
                      curEntityId={projectId}
                      givenProfileIdArr={[]}
                    />
                  </div>
                }
                validation={validationObj?.vendor ? validationObj?.vendor : validationObj?.["vendor.value"] ? validationObj?.["vendor.value"] : ""}
              />
            </div>

            <div className={classes.lableValueCont}>
              <InputWrapper
                lable={"Owner"}
                inputComp={
                  <div className="inputCont">
                    <div className={classes.selectedUserSty}>
                      <Avatar
                        src={
                          opsOrder?.projectParent?.displayPicture?.thumbUrl
                            ? opsOrder?.projectParent?.displayPicture?.thumbUrl
                            : opsOrder?.projectParent?.displayPicture?.url
                        }
                        alt={selectedProject?.data?.parent?.displayName}
                      />
                      <div style={{ marginLeft: "5px" }}>
                        <h3>{opsOrder?.projectParent?.displayName}</h3>
                        <p>{opsOrder?.projectParent?.username}</p>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
            <div className={classes.lableValueCont}>
              <InputWrapper
                lable={<>Project<span className={classes.starSty}>*</span></>}
                inputComp={
                  <div className="inputCont">
                    <PaginatedEntityDropdown
                      value={selectedProject}
                      onChange={(value) => {
                        setSelectedProject(value);
                      }}
                      isMulti={false}
                      entity={"Project"}
                      curEntityId={projectId}
                      givenProfileIdArr={[user?.profile]}
                      palCreate={false}
                      disabled={needToMakeDisable}
                      noFilter={true}
                      givenFilterOption={[
                        {
                          option: "Network",
                          types: ["Project"]
                        }
                      ]}
                    />
                  </div>
                }
                validation={validationObj?.project ? validationObj?.project : validationObj?.["project.value"] ? validationObj?.["project.value"] : ""}
              />
            </div>
            <div className={classes.lableValueCont}>
              <InputWrapper
                lable={
                  <>
                    Order Deadline<span className={classes.starSty}>*</span>
                  </>
                }
                inputComp={
                  <div className="inputCont">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        value={deadline}
                        disabled={needToMakeDisable}
                        fullWidth
                        margin="normal"
                        id="due-date-picker"
                        format="MM/dd/yyyy"
                        onChange={(date) => {
                          setDeadline(date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                }
                validation={
                  validationObj?.orderDeadline
                    ? validationObj?.orderDeadline
                    : ""
                }
              />
            </div>
            <div className={classes.lableValueCont}>
              <InputWrapper
                lable={
                  <>
                    Expected Arrival<span className={classes.starSty}>*</span>
                  </>
                }
                inputComp={
                  <div className="inputCont">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        value={arrival}
                        disabled={needToMakeDisable}
                        fullWidth
                        margin="normal"
                        id="due-date-picker"
                        format="MM/dd/yyyy"
                        onChange={(date) => {
                          setArrival(date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                }
                validation={
                  validationObj?.expectedArrival
                    ? validationObj?.expectedArrival
                    : ""
                }
              />
            </div>
            <div className={classes.lableValueCont}>
              <InputWrapper
                lable={
                  <>
                    Advance Amount<span className={classes.starSty}>*</span>
                  </>
                }
                inputComp={
                  <div className="inputCont">
                    <TextFieldNumberFormated
                      size="small"
                      placeholder="Enter Advance Amount"
                      autoComplete="off"
                      disabled={needToMakeDisable}
                      style={{ width: "100%" }}
                      value={advanceAmount}
                      onChange={(e) => {
                        setAdvanceAmount(e.target.value);
                      }}
                    />
                  </div>
                }
                validation={
                  validationObj?.["advance amount"]
                    ? validationObj?.["advance amount"]
                    : ""
                }
              />
            </div>
            <div className={classes.lableValueCont}>
              <InputWrapper
                lable={
                  <>
                    Retention Percentage
                    <span className={classes.starSty}>*</span>
                  </>
                }
                inputComp={
                  <div className="inputCont">
                    <TextField
                      id="standard-basic"
                      size="small"
                      placeholder="Enter retention percentage"
                      autoComplete="off"
                      type="number"
                      variant="standard"
                      disabled={needToMakeDisable}
                      value={retantionPercentage}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <FaPercent />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setRetantionPercentage(e.target.value);
                      }}
                    />
                  </div>
                }
                validation={
                  validationObj?.["retantion percentage"]
                    ? validationObj?.["retantion percentage"]
                    : ""
                }
              />
            </div>
          </div>
        </Paper>

        <Paper className={classes.paperCont} elevation={2}>
  <div className={classes.paperHeader}>
    <h2>Linked Documents</h2>
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={() => setOpenLinkedDocsDrawer(true)}
    >
      Manage Linked Docs
    </Button>
  </div>
  <Grid container spacing={3}>
    {linkedDocsDetails.length > 0 ? (
      linkedDocsDetails.map((doc) => (
        <Grid item xs={12} sm={6} md={4} key={doc._id}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                <Typography variant="h6" component="h3" style={{ flex: 1 }}>
                  {doc.name}
                </Typography>
                <Chip 
                  label={doc.status} 
                  color={doc.status === 'Approved' ? 'primary' : 'default'} 
                  size="small" 
                  className={classes.chip}
                />
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <div className={classes.iconText}>
                  <Description fontSize="small" />
                  <Typography variant="body2">{doc.type}</Typography>
                </div>
                {!['Material Request'].includes(doc.type) && (
                  <Typography variant="body2" className={classes.boldText}>
                    ₹{numberWithCommas(doc.totalAmountVal || 0)}
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))
    ) : (
      <Grid item xs={12}>
        <Typography variant="body1" align="center">No linked documents available.</Typography>
      </Grid>
    )}
  </Grid>
</Paper>

    {opsOrder.type === "Purchase Order"  && (
            <Paper className={classes.paperCont} elevation={2}>
              <div className={classes.paperHeader}>
                <h2>Linked GRN</h2>
                <h2>₹{numberWithCommas(parseFloat(totalGRNAmount).toFixed(2))}</h2>
              </div>
              <GRNCertificatesTable grnData={linkedGRNs} />
            </Paper>
          )}

        {certificates && certificates.length > 0 && (
          <Paper className={classes.paperCont} elevation={2}>
            <div className={classes.paperHeader}>
              <h2>Certificates</h2>
              <h2>₹{numberWithCommas(parseFloat(totalCFAmount).toFixed(2))}</h2>
            </div>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" size={"small"}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Certificate No.</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Created By</TableCell>
                    <TableCell align="center">Created At</TableCell>
                    <TableCell align="center">Updated At</TableCell>
                    <TableCell align="right" style={{ width: "240px" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {certificates &&
                    certificates.map((cfData) => (
                      <TableRow>
                        <TableCell align="left">
                          {cfData?.certificateNumber}
                        </TableCell>
                        <TableCell align="center">
                          {numberWithCommas(
                            parseFloat(cfData?.netPayment || 0)
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <div className={classes.selectedUserOrgSty}>
                            <Avatar
                              src={
                                cfData?.user?.displayPicture?.thumbUrl
                                  ? cfData?.user?.displayPicture?.thumbUrl
                                  : cfData?.user?.displayPicture?.url
                              }
                              alt={cfData?.user?.displayName}
                            />
                            <div style={{ marginLeft: "5px" }}>
                              <h3>{cfData?.user?.displayName}</h3>
                              <p>{cfData?.user?.username}</p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          {moment(cfData?.updatedAt).format("DD/MMM/YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {moment(cfData?.createdAt).format("DD/MMM/YYYY")}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              if (cfData?.walletId) {
                                var path = isDev
                                  ? `/finance/${cfData?.walletId}/workorder-certificate/edit/${cfData?._id}`
                                  : `https://accounts.reallist.ai/finance/${cfData?.walletId}/workorder-certificate/edit/${cfData?._id}`;

                                if (isDev) {
                                  history.push(path);
                                } else {
                                  const token = localStorage.getItem("token");
                                  setAuthCookieData({ user, token });
                                  window.open(path, "_blank");
                                }
                              }
                            }}
                          >
                            View Details
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              if (cfData?.walletId) {
                                var path = isDev
                                  ? `/finance/${cfData?.walletId}/bill/edit/${cfData?.transaction}`
                                  : `https://accounts.reallist.ai/finance/${cfData?.walletId}/bill/edit/${cfData?.transaction}`;
                                if (isDev) {
                                  history.push(path);
                                } else {
                                  const token = localStorage.getItem("token");
                                  setAuthCookieData({ user, token });
                                  window.open(path, "_blank");
                                }
                              }
                            }}
                          >
                            View Bill
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}

        <Paper className={classes.paperCont} elevation={2}>
          <div className={classes.paperHeader}>
            <h2>Edit Items</h2>
            {needToMakeDisable ? (
              <div></div>
            ) : (
              <CloneBillItems
                procurementType={"Material Request"}
                identifier={opsOrder?.projectParent?._id}
                curBillListId={opsOrder?.billList}
                needToReloadFun={() => {
                  setNeedToReloadBillList(!needToReloadBillList);
                }}
              />
            )}
          </div>
          {opsOrder?.project?._id && opsOrder?.billList && (
            <BillItemsTable
              billListId={opsOrder?.billList}
              wallet={wallet}
              projectProfileId={projectProfileId}
              setBillListError={setBillListError}
              setBillListTotals={setBillListTotals}
              needToMakeDisable={needToMakeDisable}
              billListTotals={billListTotals}
              needToReloadBillList={needToReloadBillList}
              setNeedToReloadBillList={setNeedToReloadBillList}
            />
          )}
        </Paper>

        {opsOrder?._id && (
          <Paper className={classes.paperCont} elevation={2}>
            <div className={classes.paperHeader}>
              <h2>Attachments</h2>
              <h2></h2>
            </div>
            <AttachmentsHandler
              parentData={opsOrder}
              setParentData={setOpsOrder}
              parentModel={"OpsOrder"}
              needToMakeDisable={needToMakeDisable}
              setLoadingBool={setLoadingBool}
            />
          </Paper>
        )}

        <NotifyAlert
          alertObj={alertObjValidation}
          setAlertObj={setAlertObjValidation}
        />
        <LinkedDocsDrawer
            open={openLinkedDocsDrawer}
            onClose={() => setOpenLinkedDocsDrawer(false)}
            identifier={opsOrder?.projectParent?._id}
            opsOrder={opsOrder}
            linkedDocs={opsOrder.linkedDocs || []}
            setLinkedDocs={handleLinkedDocsUpdate}         
            profileId={projectProfileId}
            opsTypesList = {["Material Request"]}
            defaultopsType = "Material Request"
            curBillListId={opsOrder?.billList}
                needToReloadFun={() => {
                  setNeedToReloadBillList(!needToReloadBillList);
            }}
            selectOne = {false}
            
        />
      </div>
    </div>
  );
};

export default PurchaseOrderEdit;
