import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, Paper, Avatar, Grid,Card,CardContent,Box,Typography, Chip  } from "@material-ui/core";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import LinearProgress from "@material-ui/core/LinearProgress";
import { ArrowDropDown , Description} from "@material-ui/icons";
import MyPopOver from "../../../styled/CommonComponents/MyPopOver";
import BillItemsTable from "../../../finance2o/commonComponent/Bill.Item.Table/Bill.Items.Table";
import { mainRoles } from '../../../../helpers/contactHelper';
import ApprovalAndActions from "../../../approval/ApprovalAndActions";
import * as yup from "yup";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useDebounce } from "react-use";
import NotifyAlert from "../../../styled/CommonComponents/NotifyAlert";
import "../../../styled/agGrid.css";
import { MdOutlineForkRight } from "react-icons/md";
import CloneBillItems from "../Clone.Bill.Items";
import InputWrapper from "../../../styled/CommonComponents/InputWarpper";
import AttachmentsHandler from "../../../styled/CommonComponents/Attachments.Handler";
import ApprovalActionDrawer from "../../../approval/ApprovalActionDrawer";
import LockIcon from '@material-ui/icons/Lock';
import { allStatusOptions } from "../../../../helpers/allStatuses";
import PaginatedEntityDropdown from "../../../styled/CommonComponents/PaginatedEntityDropdown";
import Api from "../../../../helpers/Api";
import LinkedDocsDrawer from "../Workorder/LinkedDocsDrawer";
import Alert from '@material-ui/lab/Alert';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "white"
    },
    backArrow: {
        fontSize: "30px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "20px",
        }
    },
    editBody: {
        width: "100%",
        height: "calc(100% - 50px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "30px 25px 100px",
        [theme.breakpoints.down('sm')]: {
            padding: "10px 10px 50px",
        }
    },
    topBar: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #ebeaea",
        paddingRight: "20px"
    },
    barLeft: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    barRight: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    },
    headerTitle: {
        fontSize: "20px",
        fontWeight: "550",
        marginLeft: "10px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
            fontWeight: "510",
            marginLeft: "0px",
        },
    },
    optionsCont: {
        padding: "5px"
    },
    typeSingleOptionSty: {
        padding: "4px 15px",
        fontSize: "16px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    },
    sectionTop: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #d0cfcf",
        marginBottom: "15px",
        padding: "5px",
        "& h3": {
            fontSize: "21px",
            fontWeight: "600",
            color: "#193B56"
        },
        [theme.breakpoints.down('sm')]: {
            "& h3": {
                fontSize: "18px",
                fontWeight: "600",
            },
        }
    },
    basicDataCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    paperCont: {
        width: "100%",
        padding: "15px",
        marginBottom: "50px",
    },
    paperHeader: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "15px",
        "& h2": {
            fontSize: "18px",
            fontWeight: "600",
        }
    },
    lableValueCont: {
        width: "33.33%",
        padding: "10px",
        "& .inputCont": {
            width: "100%"
        }
    },
    addVendorSty: {
        width: "100%",
        color: "#aeacac",
        borderRadius: "0px",
        cursor: "pointer",
        padding: "9px 10px"
    },
    selectedUserSty: {
        width: "100%",
        padding: "2px 5px",
        cursor: "pointer",
        borderRadius: "0px",
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            marginLeft: "5px"
        },
        "& .MuiIconButton-root": {
            padding: "3px"
        },
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
        "& h3": {
            fontSize: "13px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "10px",
            fontWeight: "400",
            color: "gray"
        }
    },
    errorSty: {
        fontSize: "12px",
        fontWeight: "400",
        height: "4px",
        width: "100%",
        color: "red",
    },
    starSty: {
        color: "red"
    },
    card: {
        height: '90%',
        width: '70%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'all 0.3s',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: theme.shadows[4],
        },
      },
      cardContent: {
        flexGrow: 1,
        paddingBottom: theme.spacing(1),
      },
      chip: {
        marginLeft: theme.spacing(1),
      },
      iconText: {
        display: 'flex',
        alignItems: 'center',
        '& > *:first-child': {
          marginRight: theme.spacing(1),
        },
      },
      boldText: {
        fontWeight: 'bold',
      },
}));

const userOrgPalParam = [
    {
        entity: "User",
        scop: [
            {
                type: "Global",
                role: []
            }
        ]
    },
    {
        entity: "Organization",
        scop: [
            {
                type: "Global",
                role: []
            }
        ]
    },
    {
        entity: "User(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Group(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Organization(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    }
]

const GRNEdit = ({
    opsOrder, setOpsOrder, wallet, projectProfileId, projectId, getOpsOrder,
    updateOpsOrderWithUpdate, updateOpsOrderWithOutUpdate, needToMakeDisable, 
    opsOrderStatus, setOpsOrderStatus, activeStage, needToMakeStatusDisable
}) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const { opsOrderId } = useParams();
    const { user } = useSelector((state) => state.auth);

    //helpers
    const [openVendorDrawer, setOpenVendorDrawer] = useState(false)
    const [loadingBool, setLoadingBool] = useState(false);
    const [validationObj, setValidationObj] = useState(null)
    const [billListError, setBillListError] = useState(false)
    const [alertObjValidation, setAlertObjValidation] = useState(null)
    const [needToReloadBillList, setNeedToReloadBillList] = useState(false)
    const [openApprovalFlow, setOpenApprovalFlow] = useState(false)
    const [openAction, setOpenAction] = useState(false)
    const [actionStatus, setActionStatus] = useState("")
    const [openLinkedDocsDrawer, setOpenLinkedDocsDrawer] = useState(false);
    const [linkedDocsDetails, setLinkedDocsDetails] = useState([]);
    const [linkedDocs, setLinkedDocs] = useState(opsOrder.linkedDocs || []);
    const [linkedPOAmount, setLinkedPOAmount] = useState(0);
    const [grnTotalAmount, setGrnTotalAmount] = useState(0);
    const [linkedGRNs, setLinkedGRNs] = useState([]);
    const [totalGRNAmount, setTotalGRNAmount] = useState(0);
   


    //values
    const [selectedProject, setSelectedProject] = useState(null)
    const [deadline, setDeadline] = useState(new Date())
    const [arrival, setArrival] = useState(new Date())
    const [billListTotals, setBillListTotals] = useState({
        taxExclTotalAmount: 0,
        taxInclTotalAmount: 0,
        totalTaxAmount: 0,
    })



    const fetchLinkedDocsDetails = async (docIds) => {
        try {
            const response = await Api.post('/procurement/ops/get-linked-docs', {
                linkedDocIds: docIds
            });
            setLinkedDocsDetails(response.linkedDocs);
            if (response.linkedDocs.length > 0 && response.linkedDocs[0].type === "Purchase Order") {
                setLinkedPOAmount(response.linkedDocs[0].totalAmountVal || 0);
            }
        } catch (error) {
            console.error('Error fetching linked documents details:', error);
        }
    };

    useEffect(() => {
        if (opsOrder) {
            setLinkedDocs(opsOrder.linkedDocs || []);
            setOpsOrderStatus(opsOrder.status || "Draft");
            if (opsOrder.project?.profile?._id) {
                setSelectedProject({
                    value: opsOrder.project.profile._id,
                    label: opsOrder.project.profile.parent?.displayName,
                    data: opsOrder.project.profile
                });
            } else {
                setSelectedProject(null);
            }
            setBillListTotals({
                taxExclTotalAmount: (parseFloat(opsOrder.untaxedAmountVal || 0)).toFixed(2),
                taxInclTotalAmount: (parseFloat(opsOrder.totalAmountVal || 0)).toFixed(2),
                totalTaxAmount: (parseFloat(opsOrder.totalAmountVal || 0) - parseFloat(opsOrder.untaxedAmountVal || 0)).toFixed(2)
            });
            setDeadline(opsOrder.orderDeadline || new Date());
            setArrival(opsOrder.expectedArrival || new Date());
        }
    }, [opsOrder]);

    useEffect(() => {
        if (linkedDocs.length > 0) {
            fetchLinkedDocsDetails(linkedDocs);
        } else {
            setLinkedDocsDetails([]);
            setLinkedPOAmount(0);
        }
    }, [linkedDocs]);

    useEffect(() => {
        setGrnTotalAmount(parseFloat(billListTotals.taxInclTotalAmount));
    }, [billListTotals]);

    useEffect(() => {
        setValidationObj(null);
    }, [selectedProject, deadline, arrival, billListTotals]);

    const handleLinkedDocsUpdate = async (newLinkedDocs) => {
        setLinkedDocs(newLinkedDocs);
        try {
            await updateOpsOrderWithUpdate(opsOrder._id, { linkedDocs: newLinkedDocs });
        } catch (error) {
            console.error("Error updating backend with new linked docs:", error);
        }
    };

    useDebounce(() => {
        if (opsOrder?._id && selectedProject?.data?.parent?._id !== opsOrder?.project?._id) {
            const obj = {
                project: selectedProject?.data?.parent?._id
            };
            updateOpsOrderWithUpdate(opsOrder._id, obj);
        }
    }, 2000, [selectedProject, opsOrder]);

    useDebounce(() => {
        if (opsOrder?._id) {
            const hasChanges = 
                billListTotals?.taxInclTotalAmount != opsOrder?.totalAmountVal ||
                opsOrder?.expectedArrival != arrival ||
                opsOrder?.orderDeadline != deadline;

            if (hasChanges) {
                const obj = {
                    untaxedAmountVal: parseFloat(billListTotals?.taxExclTotalAmount || 0),
                    totalAmountVal: parseFloat(billListTotals?.taxInclTotalAmount || 0),
                    expectedArrival: arrival,
                    orderDeadline: deadline
                };
                updateOpsOrderWithOutUpdate(opsOrder._id, obj);
            }
        }
    }, 2000, [billListTotals, deadline, arrival, opsOrder]);
     
    console.log("The linked po amount is:" , linkedPOAmount);
    console.log("The grn total is:" , grnTotalAmount);

    const fetchLinkedGRNs = async (opsOrderId) => {
        try {
          const response = await Api.post('/procurement/ops/get-linked-grns', {
            opsOrderId: opsOrderId
          });
          console.log("The linked GRNs:", response.linkedGRNs);
          setLinkedGRNs(response.linkedGRNs.filter(grn => grn._id !== opsOrder._id));
          const total = response.linkedGRNs.reduce((sum, grn) => sum + (grn.totalAmountVal || 0), 0);
          setTotalGRNAmount(total);
        } catch (error) {
          console.error('Error fetching linked GRNs:', error);
        }
      };

      useEffect(() => {
        if (opsOrder._id && opsOrder.type === "GRN") {
          fetchLinkedGRNs(opsOrder.linkedDocs[0]);
        }
      }, [opsOrder._id, opsOrder.type, opsOrder.linkedDocs]);

    const updateStatus = async (status) => {
        const basicDataSchema = yup.object().shape({
            project: yup
                .object()
                .shape({ value: yup.string().required("Please select a project") })
                .required("Please select a project")
                .typeError(({ path }) => "Please select a project"),

            expectedArrival: yup
                .date()
                .required("Please enter arrival date"),

            grnAmount: yup
                .number()
                .test('is-less-than-po', 'The total GRN amount must be less than the Purchase Order amount', function (value) {
                    console.log("value is:" , value)
                    return value < linkedPOAmount;
                }),
        });

        
        const formData = {
            project: selectedProject,
            expectedArrival: arrival,
            grnAmount: totalGRNAmount,
        }

        try {
            await basicDataSchema.validate(formData, { abortEarly: false });
            setActionStatus(status);
            setOpenAction(true);
        } catch (err) {
            console.log(err);
            let errorArr = err?.inner;
            let obj = {};
            errorArr.forEach((err) => {
                obj[err?.path] = err?.message;
            });
            setValidationObj(obj);
            setAlertObjValidation({
                show: true,
                message: "Please enter valid data in the below forms",
                status: "error"
            });
        }
    }
    
    const handleCardClick = (docId, docType) => {
        if (docType === "GRN") {
          history.push(`/procurements/ops-order/${docId}/edit`);
        }
      };

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className={classes.root}>
            <div className={classes.topBar}>
                <div className={classes.barLeft}>
                    <IconButton onClick={() => { history.goBack() }}>
                        <KeyboardBackspaceIcon className={classes.backArrow} />
                    </IconButton>
                    <h3 className={classes.headerTitle}>
                        {opsOrder?.name}
                    </h3>
                </div>
                <div className={classes.barRight}>
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => { setOpenApprovalFlow(true) }}
                        endIcon={<MdOutlineForkRight />}
                    >
                        Approval Flow
                    </Button>
                    <ApprovalAndActions
                        openApprovalFlow={openApprovalFlow}
                        setOpenApprovalFlow={setOpenApprovalFlow}
                        loadingBool={loadingBool}
                        setLoadingBool={setLoadingBool}
                        parent={opsOrder?._id}
                        parentModelName={"OpsOrder"}
                        requestId={opsOrder?.approvalRequest?._id}
                        needToMakeDisable={needToMakeStatusDisable}
                        activeStage={activeStage}
                    />
                    {needToMakeStatusDisable ? (
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            disabled
                            endIcon={<LockIcon />}
                        >
                            {opsOrderStatus ? opsOrderStatus : "STATUS"}
                        </Button>
                    ) : (
                        <>
                            <MyPopOver
                                closeOnClick={true}
                                appearContent={
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        endIcon={<ArrowDropDown />}
                                    >
                                        {opsOrderStatus ? opsOrderStatus : "STATUS"}
                                    </Button>
                                }
                                showContent={
                                    <div className={classes.optionsCont}>
                                        {allStatusOptions[opsOrder?.type][opsOrder?.status] && allStatusOptions[opsOrder?.type][opsOrder?.status].map((statusOption, i) => (
                                            <div
                                                key={i}
                                                className={classes.typeSingleOptionSty}
                                                onClick={() => { updateStatus(statusOption) }}
                                            >
                                                {statusOption}
                                            </div>
                                        ))}
                                    </div>
                                }
                            />
                            <ApprovalActionDrawer
                                openAction={openAction}
                                setOpenAction={setOpenAction}
                                loadingBool={loadingBool}
                                setLoadingBool={setLoadingBool}
                                actionType={"takeAction"}
                                parent={opsOrder?._id}
                                parentModelName={"OpsOrder"}
                                statusField={"status"}
                                statusValue={actionStatus}
                                requestId={opsOrder?.approvalRequest?._id}
                                activeStage={activeStage}
                                actionCallBack={(type, curStatues) => {
                                    getOpsOrder()
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
            <div style={{ width: "100%", height: "4px" }}>
                {loadingBool && (<LinearProgress />)}
            </div>
            <div className={classes.editBody}>
                <Paper className={classes.paperCont} elevation={2}>
                    <div className={classes.paperHeader}>
                        <h2>GRN Data</h2>
                    </div>
                    <div className={classes.basicDataCont}>
                        <div className={classes.lableValueCont}>
                            <InputWrapper
                                lable={"Owner"}
                                inputComp={
                                    <div className="inputCont">
                                        <div className={classes.selectedUserSty}>
                                            <Avatar
                                                src={opsOrder?.projectParent?.displayPicture?.thumbUrl || opsOrder?.projectParent?.displayPicture?.url}
                                                alt={selectedProject?.data?.parent?.displayName}
                                            />
                                            <div style={{ marginLeft: "5px" }}>
                                                <h3>{opsOrder?.projectParent?.displayName}</h3>
                                                <p>{opsOrder?.projectParent?.username}</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                        <div className={classes.lableValueCont}>
                            <InputWrapper
                                lable={<>Project<span className={classes.starSty}>*</span></>}
                                inputComp={
                                    <div className="inputCont">
                                        <PaginatedEntityDropdown
                                            value={selectedProject}
                                            onChange={(value) => {
                                                setSelectedProject(value);
                                            }}
                                            isMulti={false}
                                            entity={"Project"}
                                            curEntityId={projectId}
                                            givenProfileIdArr={[user?.profile]}
                                            palCreate={false}
                                            disabled={needToMakeDisable}
                                            noFilter={true}
                                            givenFilterOption={[
                                                {
                                                    option: "Network",
                                                    types: ["Project"]
                                                }
                                            ]}
                                        />
                                    </div>
                                }
                                validation={validationObj?.project ? validationObj?.project : validationObj?.["project.value"] ? validationObj?.["project.value"] : ""}
                            />
                        </div>
                     
                        <div className={classes.lableValueCont}>
                            <InputWrapper
                                lable={<>Arrival<span className={classes.starSty}>*</span></>}
                                inputComp={
                                    <div className="inputCont">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                value={arrival}
                                                disabled={needToMakeDisable}
                                                fullWidth
                                                margin="normal"
                                                id="expected-arrival-picker"
                                                format="MM/dd/yyyy"
                                                onChange={(date) => {
                                                    setArrival(date)
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                }
                                validation={validationObj?.expectedArrival ? validationObj?.expectedArrival : ""}
                            />
                        </div>
                    </div>
                </Paper>
                {validationObj?.grnAmount && (
                    <Alert severity="error" style={{ marginTop: '20px' }}>{validationObj.grnAmount}</Alert>
                )}

                <NotifyAlert
                    alertObj={alertObjValidation}
                    setAlertObj={setAlertObjValidation}
                />

                <Paper className={classes.paperCont} elevation={2}>
  <div className={classes.paperHeader}>
    <h2>Linked Documents</h2>
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={() => setOpenLinkedDocsDrawer(true)}
    >
      Manage Linked Docs
    </Button>
  </div>
  <Grid container spacing={3}>
    {linkedDocsDetails.length > 0 ? (
      linkedDocsDetails.map((doc) => (
        <Grid item xs={12} sm={6} md={4} key={doc._id}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                <Typography variant="h6" component="h3" style={{ flex: 1 }}>
                  {doc.name}
                </Typography>
                <Chip 
                  label={doc.status} 
                  color={doc.status === 'Approved' ? 'primary' : 'default'} 
                  size="small" 
                  className={classes.chip}
                />
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <div className={classes.iconText}>
                  <Description fontSize="small" />
                  <Typography variant="body2">{doc.type}</Typography>
                </div>
                {!['Material Request'].includes(doc.type) && (
                  <Typography variant="body2" className={classes.boldText}>
                    ₹{numberWithCommas(doc.totalAmountVal || 0)}
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))
    ) : (
      <Grid item xs={12}>
        <Typography variant="body1" align="center">No linked documents available.</Typography>
      </Grid>
    )}
    {linkedGRNs.map((grn) => (
      <Grid item xs={12} sm={6} md={4} key={grn._id}>
        
        <Card           
            className={classes.card}
            onClick={() => handleCardClick(grn._id, "GRN")}
            style={{ cursor: 'pointer' }}>
          <CardContent className={classes.cardContent}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
              <Typography variant="h6" component="h3" style={{ flex: 1 }}>
                {grn.name}
              </Typography>
              <Chip 
                label={grn.status} 
                color={grn.status === 'Approved' ? 'primary' : 'default'} 
                size="small" 
                className={classes.chip}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <div className={classes.iconText}>
                <Description fontSize="small" />
                <Typography variant="body2">GRN</Typography>
              </div>
              <Typography variant="body2" className={classes.boldText}>
                ₹{numberWithCommas(grn.totalAmountVal || 0)}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
</Paper>


                <Paper className={classes.paperCont} elevation={2}>
                    <div className={classes.paperHeader}>
                        <h2>Edit Items</h2>
                        {!needToMakeDisable && (
                            <CloneBillItems
                                procurementType={"Purchase Order"}
                                identifier={opsOrder?.projectParent?._id}
                                curBillListId={opsOrder?.billList}
                                needToReloadFun={() => {
                                    setNeedToReloadBillList(!needToReloadBillList)
                                }}
                            />
                        )}
                    </div>
                    {opsOrder?.project?._id && opsOrder?.billList && (
                        <BillItemsTable
                            billListId={opsOrder?.billList}
                            wallet={wallet}
                            projectProfileId={projectProfileId}
                            setBillListError={setBillListError}
                            setBillListTotals={setBillListTotals}
                            needToMakeDisable={needToMakeDisable}
                            billListTotals={billListTotals}
                            needToReloadBillList={needToReloadBillList}
                            setNeedToReloadBillList={setNeedToReloadBillList}
                        />
                    )}
                </Paper>

                {opsOrder?._id && (
                    <Paper className={classes.paperCont} elevation={2}>
                        <div className={classes.paperHeader}>
                            <h2>Attachments</h2>
                        </div>
                        <AttachmentsHandler
                            parentData={opsOrder}
                            setParentData={setOpsOrder}
                            parentModel={"OpsOrder"}
                            needToMakeDisable={needToMakeDisable}
                            setLoadingBool={setLoadingBool}
                        />
                    </Paper>
                )}

                {validationObj?.grnAmount && (
                    <Alert severity="error" style={{ marginTop: '20px' }}>{validationObj.grnAmount}</Alert>
                )}

                <NotifyAlert
                    alertObj={alertObjValidation}
                    setAlertObj={setAlertObjValidation}
                />

                <LinkedDocsDrawer
                    open={openLinkedDocsDrawer}
                    onClose={() => setOpenLinkedDocsDrawer(false)}
                    identifier={opsOrder?.projectParent?._id}
                    opsOrder={opsOrder}
                    linkedDocs={linkedDocs}
                    setLinkedDocs={handleLinkedDocsUpdate}         
                    profileId={projectProfileId}
                    opsTypesList={["Purchase Order"]}
                    defaultopsType="Purchase Order"
                    curBillListId={opsOrder?.billList}
                    needToReloadFun={() => {
                        setNeedToReloadBillList(!needToReloadBillList);
                    }}
                    selectOne={true}
                    model="GRN"
                />
            </div>
        </div>
    );
};

export default GRNEdit;