import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { CircularProgress, makeStyles } from "@material-ui/core";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import Api from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import TableContainer from "../../styled/generic/TableContainer";
import StatusChip from "../../styled/generic/StatusChip";

const useStyles = makeStyles({
  outlinedInput: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    padding: "7px 10px", // Adjust padding as needed
    "&:focus": {
      borderColor: "#2196f3",
    },
  },
});

function EnhancedTableHead(props) {
  const { columns } = props;

  const getCellWidth = (headerName) => {
    switch (headerName) {
      case "ID":
        return "60px";

      case "Email":
        return "250px";

      default:
        return "auto";
    }
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((col) => {
          let width = getCellWidth(col.headerName);
          return (
            <TableCell key={col.id} style={{ width: width }}>
              {col.headerName}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const classes = useStyles();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Box display="flex" alignItems="center" style={{ width: "100%" }}>
          <WorkOutlineOutlinedIcon />
          <Typography
            sx={{ flex: "1 1 100%", marginLeft: 1 }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Showing All OPS Orders
          </Typography>
        </Box>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const OpsOrderList = (props) => {
  const [selected, setSelected] = useState([]);
  const [opsOrders, setOpsOrders] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { filteredOpsOrderType, searchQuery, setSearchQuery } = props;

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const organizationId = urlParams.get("organizationId");
  const projectId = urlParams.get("projectId");

  const processOpsOrders = (list) => {
    if (list.length > 0) {
      const newList = list.map((item, index) => {
        return {
          id: index + 1,
          _id: item._id,
          opsOrderName: item?.name || "Untitled",
          username: item?.user?.displayName,
          email: item?.user?.email,
          createdAt: item?.created
            ? moment(item.created).format("DD MMMM YYYY")
            : "",
          project: item?.project?.displayName,
          organization: item?.organization?.displayName,
          billList: item?.billList,
          type: item?.type,
          status: item?.status,
        };
      });
      return newList;
    } else {
      return [];
    }
  };

  const { user } = useSelector((state) => state.auth);
  const getOpsOrders = async () => {
    try {
      setLoading(true);
      let query = { userId: user._id };

      if (projectId) {
        query.projectId = projectId;
      }

      if (organizationId) {
        query.organizationId = organizationId;
      }

      const { data } = await Api.post("/procurement/ops/get", query);
      // setOpsOrders(data);
      const rowData = processOpsOrders(data);
      setRows(rowData);
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch Ops orders",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOpsOrders();
  }, []);

  useEffect(() => {
    if (filteredOpsOrderType === "All") {
      getOpsOrders();
    } else {
      getOpsOrders().then(() => {
        setRows((opsOrders) =>
          opsOrders.filter((item) => item.type === filteredOpsOrderType)
        );
      });
    }
  }, [filteredOpsOrderType]);

  const history = useHistory();

  const handleClick = (event, row) => {
    if (row.type === "GRN") {
      history.push(`/procurements/grn/${row._id}`);
    } else {
      history.push(`/procurements/ops-order/${row._id}/edit`);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const [filteredRequest, setFilteredRequest] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const filteredRequests = rows?.filter((value) =>
        value.opsOrderName.toUpperCase().includes(searchQuery.toUpperCase())
      );
      setFilteredRequest(filteredRequests);
    } else {
      setFilteredRequest(rows);
    }
  }, [searchQuery, rows]);

  return (
    <Box>
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ width: "100%", height: 300 }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <TableContainer
          columns={[
            "MR NO",
            "STATUS",
            "TYPE",
            "PROJECT",
            "ORGANIZATION",
            "CREATED AT",
            "CREATED BY",
          ]}
          data={filteredRequest}
        >
          {filteredRequest?.map((row, index) => (
            <tr
              onClick={() => {
                history.push(`/procurements/ops-order/${row?._id}/edit`)
              }}
            >
              <td>{row.opsOrderName}</td>
              <td>
                <StatusChip
                  label={row?.status || "-"}
                  variant={
                    ["Pending"].includes(row?.status)
                      ? "Warning"
                      : ["Draft"].includes(row?.status)
                      ? "Success"
                      : "Info"
                  }
                />
              </td>
              <td>{row?.type}</td>
              <td>{row?.project}</td>
              <td>{row?.organization}</td>
              <td>{row?.createdAt}</td>
              <td>{row?.username}</td>
            </tr>
          ))}
        </TableContainer>
      )}
    </Box>
  );
};

export default OpsOrderList;