import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreditNoteOtherSettings from './Credit.Note.Other.Settings';
import CreditDebitViewMode from './Credit.Debit.View.Mode';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        position: "relative",
    },
    page1: {
        width: "100%",
        height: "100%",
    },
    page2: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "0px",
        left: "0px",
        zIndex: "1000",
        backgroundColor: "white"
    },
    loaderCont: {
        position: "fixed",
        top: "0px",
        right: "0px",
        width: "100vw",
        height: "100vh",
        zIndex: "100",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    wrongCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.primary.fail
    }
}));

export default function CreditNoteEdit({
    tx, setTx, updateApi, walletId, discounts, setDiscounts,
    taxes, setTaxes, chartAccounts, setChartAccounts, offerings,
    setOfferings, terms, setTerms, txNosArr, setTxNosArr, maxNo,
    setMaxNo, bankChartAccounts, setBankChartAccounts, needToMakeStatusDisable,
    incomeChartAccounts, setIncomeChartAccounts, expenseChartAccounts,
    setExpenseChartAccounts, needToMakeDisable, activeStage
}) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();

    const { user } = useSelector((state) => state.auth);

    const [openPayment, setOpenPayment] = useState(false);
    const [loadingBool, setLoadingBool] = useState(false);


    return (
        <div className={classes.root} >
            <div className={classes.page1} >
                {walletId === tx?.firstPartyWallet._id ? (
                    <CreditNoteOtherSettings
                        tx={tx}
                        setTx={setTx}
                        discounts={discounts}
                        setDiscounts={setDiscounts}
                        taxes={taxes}
                        setTaxes={setTaxes}
                        chartAccounts={chartAccounts}
                        setChartAccounts={setChartAccounts}
                        offerings={offerings}
                        setOfferings={setOfferings}
                        terms={terms}
                        setTerms={setTerms}
                        txNosArr={txNosArr}
                        loadingBool={loadingBool}
                        setBankChartAccounts={setBankChartAccounts}
                        bankChartAccounts={bankChartAccounts}
                        incomeChartAccounts={incomeChartAccounts}
                        setIncomeChartAccounts={setIncomeChartAccounts}
                        expenseChartAccounts={expenseChartAccounts}
                        setExpenseChartAccounts={setExpenseChartAccounts}
                        setTxNosArr={setTxNosArr}
                        maxNo={maxNo}
                        setMaxNo={setMaxNo}
                        updateApi={updateApi}
                        walletId={walletId}
                        setLoadingBool={setLoadingBool}
                        setOpenPayment={setOpenPayment}
                        needToMakeDisable={needToMakeDisable}
                        activeStage={activeStage}
                        needToMakeStatusDisable={needToMakeStatusDisable}
                    />
                ) : (walletId === tx?.secondPartyWallet._id &&
                    (
                        <CreditDebitViewMode
                            tx={tx}
                            walletId={walletId}
                        />
                    )
                )}
            </div>
            {loadingBool &&
                <div className={classes.loaderCont} >
                    <CircularProgress
                        size={60}
                        thickness={3}
                        style={{ color: 'rgb(92, 144, 242)' }}
                    />
                </div>}
        </div>
    );
}