import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
    Box, Button, CircularProgress, Paper, Modal, Card,
    CardContent, CardActions, TextField as InputText,
    FormGroup, FormControlLabel, Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../styled/agGrid.css";
import { getProcurementByType } from "../../finance2o/commonComponent/transaction/api";
import NormalDrawer from "../../styled/CommonComponents/NormalDrawer";
import { CiImport } from "react-icons/ci";
import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment";
import { cloneBillItems } from "./api.call";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    tableCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflow: "auto"
    },
    tableSty: {
        width: "100%",
        border: "1px solid gray",
        borderCollapse: "collapse",
        tableLayout: "auto",
        "& th": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "550",
            padding: "0px 5px",
            textAlign: "right",
        },
        "& td": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "500",
            padding: "0px 5px",
            position: "relative",
            textAlign: "right"
        },
        "& tr": {
            height: "35px !important"
        },
        "& .MuiIconButton-root": {
            padding: "0px !important"
        }
    },
    bottomCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    }
}));

const CloneBillItems = ({ procurementType, identifier, curBillListId, needToReloadFun }) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [procurements, setProcurements] = useState([])
    const [selectedProcurements, setSelectProcurements] = useState([])
    const [openSelect, setOpenSelect] = useState(false)
    const [loader, setLoader] = useState(false)



    useEffect(() => {
        getProcurementByType({ procurementType, identifier })
            .then((data) => {
                console.log(data)
                setProcurements(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [identifier, procurementType])

    console.log("the procurement is:", procurements);

    console.log("The selected procurements are:" , selectedProcurements);

    const onCloneSubmit = async () => {
        setLoader(true)
        await cloneBillItems({
            fromBillListIds: selectedProcurements,
            toBillListId: curBillListId,
            user: user?._id
        })
            .then((data) => {
                console.log(data);
                needToReloadFun();
            })
            .catch((err) => {
                console.log(err)
            })
        setLoader(false)
        setOpenSelect(false)
    }



    return (
        <>
            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CiImport />}
                onClick={() => { setOpenSelect(true) }}
            >
                Import Items
            </Button>

            <NormalDrawer
                openDrawer={openSelect}
                setOpenDrawer={setOpenSelect}
                anchor={"right"}
                width={"40vw"}
                title={"Import Items"}
                content={<div className={classes.mainCont} >
                    <div className={classes.tableCont} >
                        <table className={classes.tableSty} >
                            <thead>
                                <tr>
                                    <th style={{ width: "30px" }} ></th>
                                    <th style={{ textAlign: "left" }} >Name</th>
                                    <th>Amount</th>
                                    <th>Project</th>
                                    <th>Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {procurements && procurements.map((ops) => (
                                    <tr>
                                        <td>
                                            <Checkbox
                                                value={selectedProcurements.includes(ops?.billList)}
                                                onChange={(evt, val) => {
                                                    let updatedArr = []
                                                    if (selectedProcurements.includes(ops?.billList)) {
                                                        updatedArr = selectedProcurements.filter((s) => s != ops?.billList)
                                                    } else {
                                                        updatedArr.push(ops?.billList)
                                                    }
                                                    setSelectProcurements(updatedArr)
                                                }}
                                                color="primary"
                                            />
                                        </td>
                                        <td style={{ textAlign: "left" }} >{ops?.name}</td>
                                        <td>{ops?.totalAmountVal}</td>
                                        <td>{ops?.project?.displayName}</td>
                                        <td>{moment(ops?.created).format("DD MMM YYYY")}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ width: "100%", height: "5px" }} >
                        {loader && (<LinearProgress />)}
                    </div>
                    <div className={classes.bottomCont} >
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<CiImport />}
                            onClick={onCloneSubmit}
                        >
                            Import
                        </Button>
                    </div>
                </div>}
            />
        </>
    );
};

export default CloneBillItems;