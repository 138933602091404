import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Drawer,
  Button,
  List,
  Card,
  CardContent,
  IconButton,
  Typography,
  Select,
  MenuItem,
  LinearProgress,
  Checkbox,
  Box,
  FormControl,
  InputLabel,
  Chip,
  Grid,
  Avatar
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Description, AttachMoney, Person, Event, Business } from '@material-ui/icons';
import { getOpsOrders, getProfileDataPopulate } from "../api.call";
import { getProcurementByType } from '../../../finance2o/commonComponent/transaction/api';
import moment from 'moment';
import Api from '../../../../helpers/Api';
import { cloneBillItems } from '../api.call';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '50%',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '50%',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)', 
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  content: {
    padding: theme.spacing(3),
    height: 'calc(100% - 64px)', // Adjust based on your header height
    overflowY: 'auto',
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
  },
  docInfoAndProject: {
    display: 'flex',
    flex: 1,
  },
  docInfo: {
    marginRight: theme.spacing(2),
  },
  docName: {
    fontWeight: 'bold',
  },
  subText: {
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
  },
  projectInfo: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  statusSection: {
    display: 'flex',
    alignItems: 'center',
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  checkbox: {
    padding: 0,
  },
  typeAndAmountRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  iconText: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      marginRight: theme.spacing(1),
    },
  },
  boldText: {
    fontWeight: 'bold',
    
  },
  saveButton: {
    position: 'sticky',
    bottom: theme.spacing(2),
    float: 'right',
  },

}));



const LinkedDocsDrawer = ({ 
  open, onClose, opsOrder,linkedDocs,setLinkedDocs,identifier,
   profileId, opsTypesList, defaultopsType,
   curBillListId,needToReloadFun, model , selectOne = true}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [availableOpsOrders, setAvailableOpsOrders] = useState([]);
  const [selectedOpsType, setSelectedOpsType] = useState(defaultopsType);
  const [filteredOpsOrders, setFilteredOpsOrders] = useState([]);
  const [loader, setLoader] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [procurements, setProcurements] = useState([])
  const [selectedProcurements, setSelectProcurements] = useState([])
  const [selectedGRNDoc, setSelectedGRNDoc] = useState(null);

  console.log("linkeddocs:", linkedDocs);




  useEffect(() => {
    getProcurementByType({ defaultopsType, identifier })
        .then((data) => {
            console.log(data)
            setProcurements(data)
        })
        .catch((err) => {
            console.log(err)
        })
}, [identifier, defaultopsType])

  useEffect(() => {
    if (open && profileId) {
      getProjectData();
    }
  }, [open, profileId]);

  useEffect(() => {
    if (profileData) {
      getAllProcurements();
    }
  }, [profileData]);

  useEffect(() => {
    filterOpsOrders();
  }, [selectedOpsType, availableOpsOrders]);

  useEffect(() => {
    if (open) {
      if (selectOne) {
        setSelectedDocs(linkedDocs);
      } else {
        setSelectedDocs(linkedDocs);
      }
    }
  }, [open, linkedDocs, selectOne]);

  const getProjectData = async () => {
    try {
      const data = await getProfileDataPopulate({ profileId });
      setProfileData(data);
    } catch (err) {
      console.error(err);
    }
  };

  const getAllProcurements = async () => {
    if (profileData && profileData?.parent?._id) {
      setLoader(true);
      try {
        const data = await getOpsOrders({
          identifier: profileData?.parent?._id,
          searchStr: "",
          curPage: 0,
          limit: 1000 // Fetch all documents
        });
        
        const allOpsOrders = data.data.filter(order => order._id !== opsOrder._id);
        setAvailableOpsOrders(allOpsOrders);
        setLoader(false);
      } catch (error) {
        console.error('Error fetching available OpsOrders:', error);
        setLoader(false);
      }
    }
  };
  console.log("Available ops order:", availableOpsOrders);

  const filterOpsOrders = () => {
    setFilteredOpsOrders(availableOpsOrders.filter(order => order.type === selectedOpsType));
  };

  const handleCheckboxChange = (doc) => {
    setSelectedDocs(prev => {
      if (!prev) return [doc._id]; 
      if (selectOne) {
        return [doc._id];
      } else {
        return prev.includes(doc._id)
          ? prev.filter(id => id !== doc._id)
          : [...prev, doc._id];
      }
    });
  };

  const onCloneSubmit = async () => {
    if (selectedDocs.length === 0) return;
    
    setLoader(true);
    try {
      const fromBillListIds = filteredOpsOrders
        .filter(order => selectedDocs.includes(order._id))
        .map(order => order.billList);

      await cloneBillItems({
        fromBillListIds,
        toBillListId: curBillListId,
        user: opsOrder.user._id
      });
      needToReloadFun();
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const handleSave = async () => {
    try {
      
      const response = await Api.post('/procurement/ops/add-linked-docs', {
        selectedOpsOrderIds: selectedDocs,
        sourceOpsOrderId: opsOrder._id,
      });
      console.log("The response is:",response.data.updatedSourceOpsOrder.linkedDocs );
      return response.data.updatedSourceOpsOrder.linkedDocs;
    } catch (error) {
      console.error('Error saving linked documents:', error);
      return null;
    }
  };

  const handleSaveClick = async () => {
    setLoader(true);
    try {
      await onCloneSubmit();
      const updatedLinkedDocs = await handleSave();
      if (updatedLinkedDocs) {
        setLinkedDocs(selectOne ? [updatedLinkedDocs[0]] : updatedLinkedDocs);
      }
      setSelectedDocs([]);
      onClose();
    } catch (error) {
      console.error('Error in handleSaveClick:', error);
    } finally {
      setLoader(false);
    }
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.drawerHeader}>
        <Typography variant="h6">Linked Documents</Typography>
        <Button onClick={onClose}>Close</Button>
      </div>
      <div className={classes.content}>
        <FormControl fullWidth className={classes.typeSelect}>
          <InputLabel shrink id="select-document-label">Select Document Type</InputLabel>
          <Select
            labelId="select-document-label"
            id="select-document"
            value={selectedOpsType}
            onChange={(e) => setSelectedOpsType(e.target.value)}
            fullWidth
          >
            {opsTypesList.map((type) => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>
        
        {filteredOpsOrders.map((doc) => (
          <Card key={doc._id} className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.headerRow}>
                <div className={classes.docInfoAndProject}>
                  <div className={classes.docInfo}>
                    <Typography variant="h6" className={classes.docName}>{doc.name}</Typography>
                    <Typography variant="body2" className={classes.subText}>
                      {moment(doc.created).format("DD/MMM/YYYY")} , {doc.user?.displayName}
                    </Typography>
                  </div>
                </div>
                <div className={classes.statusSection}>
                  <div className={classes.projectInfo}>
                    <Business fontSize="small" style={{ marginRight: theme.spacing(0.5) }} />
                    <Typography variant="body2" style={{ marginRight: theme.spacing(2) }}>{doc.project?.displayName}</Typography>
                  </div>
                  <Chip 
                    label={doc.status} 
                    color={doc.status === 'Approved' ? 'primary' : 'default'} 
                    size="small" 
                    className={classes.chip}
                  />
                    <Checkbox
                      className={classes.checkbox}
                      checked={selectedDocs ? selectedDocs.includes(doc._id) : false}
                      onChange={() => handleCheckboxChange(doc)}
                    />
                </div>
              </div>
              <div className={classes.typeAndAmountRow}>
                <div className={classes.iconText}>
                  <Description fontSize="small" />
                  <Typography variant="body2">{doc.type}</Typography>
                </div>
                {!['Bill Of Quantities', 'Material Request'].includes(doc.type) && (
                  <Typography variant="body2" className={classes.boldText}>
                    ₹{numberWithCommas(doc.totalAmountVal || 0)} :
                    ₹{numberWithCommas(doc.totalCertifiedAmount || 0)}
                  </Typography>
                )}
              </div>
            </CardContent>
          </Card>
        ))}

        
        <div style={{ width: "100%", height: "5px" }}>
          {loader && (<LinearProgress />)}
        </div>
        <div className={classes.bottomCont}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSaveClick}
          >
            Save
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default LinkedDocsDrawer;