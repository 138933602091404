import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button, IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MyPopOver from "../../../styled/CommonComponents/MyPopOver";
import BillItemEdit from "./Bill.Item.Edit";
import DeleteConfirmBox from "../../../styled/CommonComponents/DeleteConfirmBox";
import { addBillItemNew, getAllTaxsByWallet, getBillListByIdWithItems } from "../transaction/api";
import "./index.css"
import { useDebounce } from "react-use";

const useStyles = makeStyles((theme) => ({
    tableCont: {
        width: "100%",
        borderRadius: "0px"
    },
    tableSty: {
        width: "100%",
        top: "0px",
        left: "0px",
        minWidth: "1850px",
        border: "1px solid gray",
        borderCollapse: "collapse",
        tableLayout: "auto",
        "& th": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "550",
            padding: "0px"
        },
        "& td": {
            border: "1px solid gray",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "500",
            padding: "0px",
            position: "relative",
            textAlign: "right",
            paddingRight: "2px"
        }
    },
    titleBtnCont: {
        display: "flex",
        alignItems: "center",
        paddingLeft: "15px",
        "& p": {
            fontSize: "13px",
            fontWeight: "500",
        },
        "& .MuiButton-root:hover": {
            backgroundColor: theme.palette.primary.main
        }
    },
    btnCont: {
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        padding: "4px",
        borderRadius: "10px",
        marginLeft: "10px"
    },
    optionsCont: {
        padding: "5px"
    },
    singleOption: {
        display: "flex",
        alignItems: "center",
        padding: "10px",
        cursor: "pointer",
        "& img": {
            width: "20px",
            height: "auto",
            marginRight: "10px"
        },
        "& p": {
            fontSize: "15px",
            fontWeight: "500"
        },
        "&:hover": {
            backgroundColor: "#e2e2e2"
        }
    },
    width200: {
        width: "200px",
        maxWidth: "200px"
    },
    width250: {
        width: "250px",
        maxWidth: "250px"
    },
    width120: {
        width: "120px",
        maxWidth: "120px"
    },
    width150: {
        width: "150px",
        maxWidth: "150px"
    },
    overflowCont: {
        width: "100%",
        overflowX: "auto"
    },
    bottomBarForDeleteShow: {
        width: "calc(100% - 110px)",
        padding: "5px 10px",
        position: "fixed",
        bottom: "0px",
        right: "0px",
        backgroundColor: "white",
        boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "all .3s ease-in-out",
    },
    bottomBarForDeleteHide: {
        width: "calc(100% - 110px)",
        padding: "5px 10px",
        position: "fixed",
        bottom: "-80px",
        right: "0px",
        backgroundColor: "white",
        boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "all .3s ease-in-out",
    },
    selectText: {
        fontSize: "14px",
        color: "gray"
    },
    bottomRightSide: {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-root": {
            border: "1px solid black"
        },
        "& p": {
            margin: "0px 10px"
        }
    },
    iconText: {
        fontSize: "15px",
        marginLeft: "5px"
    },
    deleteConfirm: {
        width: "350px",
        padding: "25px 0px 35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& .MuiSvgIcon-root": {
            fontSize: "75px",
            color: "red"
        },
        "& h3": {
            fontSize: "20px"
        },
        "& p": {
            fontSize: "14px"
        }
    },
    starSty: {
        color: "red"
    }
}));

const BillListEdit = ({
    billListId, wallet, projectProfileId, setBillListError, setBillListTotals, billListTotals,
    needToReloadBillList, setNeedToReloadBillList, needToMakeDisable
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [billList, setBillList] = useState(null)
    const [billItems, setBillItems] = useState([])
    const [hierarchyArr, setHierarchyArr] = useState([])
    const [isCollapseChild, setCollapseChild] = useState(false)
    //for item add
    const [totalAmountForBillList, setTotalAmountForBillList] = useState(0);
    const [parentChildrenMap, setParentChildrenMap] = useState({})
    const [idBillItemMap, setIdBillItemMap] = useState({})
    const [loader, setLoader] = useState(true)
    const [workitemDeleteOpen, setWorkitemDeleteOpen] = useState(false)
    const [allSelectedIds, setAllSelectedIds] = useState([])
    const [taxs, setTaxs] = useState([])

    const buildHierarchy = (items, parent = null) => {
        const result = [];
        for (const item of items) {
            if (item?.parent == parent) {
                const children = buildHierarchy(items, item?._id);
                if (children.length > 0) {
                    item.children = children;
                }
                if (item?.numberOfChildren === 0) {
                    result.push({
                        ...item,
                        totalAmount: parseFloat(parseFloat(item?.qty || 0) * parseFloat(item?.rate || 0)).toFixed(2),
                    })
                } else {
                    result.push(item);
                }
            }
        }
        return result;
    }

    function calculateChildrenSum(items, parentId = null) {
        const children = items.filter(item => item.parent == parentId);
        let totalRateAmount = 0;
        let totalTaxAmount = 0;
        let allTotalAmount = 0;

        for (const child of children) {
            let locQty = parseFloat(child?.qTy || 0);
            let locRate = parseFloat(child?.rate || 0);
            let locTaxs = child?.taxRelations || [];
            let locTotalAmount = parseFloat(locQty * locRate).toFixed(2);
            let locTotalTaxAmount = 0;
            locTaxs.map((tax) => {
                if (tax?.type === "%") {
                    let num = locTotalAmount * (parseFloat(tax?.percent || 0) / 100)
                    locTotalTaxAmount = locTotalTaxAmount + num
                } else {
                    locTotalTaxAmount = locTotalTaxAmount + parseFloat(tax?.amount || 0)
                }
            })
            let allSum = parseFloat(locTotalAmount) + parseFloat(locTotalTaxAmount)

            totalRateAmount += parseFloat(locTotalAmount);
            totalTaxAmount += parseFloat(locTotalTaxAmount)
            allTotalAmount += parseFloat(allSum)

            const totalObj = calculateChildrenSum(items, child?._id);

            totalRateAmount += parseFloat(totalObj?.totalRateAmount || 0);
            totalTaxAmount += parseFloat(totalObj?.totalTaxAmount || 0);
            allTotalAmount += parseFloat(totalObj?.allTotalAmount);
        }

        return {
            totalRateAmount,
            totalTaxAmount,
            allTotalAmount
        };
    }

    function updateParentItems(items) {
        const updatedArr = items.map((item) => {
            if (item?.isBillGroup) {
                let objData = calculateChildrenSum(items, item?._id);
                return {
                    ...item,
                    totalRateAmount: objData?.totalRateAmount || 0,
                    totalTaxAmount: objData?.totalTaxAmount || 0,
                    allTotalAmount: objData?.allTotalAmount || 0
                }
            } else {
                return item
            }
        })
        return updatedArr;
    }

    const getInitialData = async () => {
        setLoader(true)
        await getBillListByIdWithItems({
            id: billListId
        })
            .then(async (data) => {
                const allBillItemsArr = data?.items || []
                let objParentChildren = {}
                let idDataMap = {}
                let parentChildMap = new Map();
                allBillItemsArr.map((billitem, i) => {
                    idDataMap[billitem?._id] = billitem
                    billitem?.ancestors && billitem.ancestors.forEach(parentId => {
                        if (!parentChildMap.has(parentId)) {
                            parentChildMap.set(parentId, []);
                        }
                        parentChildMap.get(parentId).push(billitem._id);
                    });
                })
                for (const [key, value] of parentChildMap) {
                    objParentChildren[key] = value
                }
                setParentChildrenMap(objParentChildren)
                setIdBillItemMap(idDataMap)
                setBillList(data || null);
                setBillItems(allBillItemsArr);
                setLoader(false)
            })
            .catch((err) => {
                console.log(err)
                setLoader(false)
            })
    }

    useDebounce(() => {
        getInitialData()
    }, 1000, [needToReloadBillList])

    useEffect(() => {
        if (wallet?._id) {
            getAllTaxsByWallet({ wallet: wallet?._id })
                .then((data) => {
                    setTaxs([
                        {
                            _id: "addNew",
                            name: "+ Add New"
                        },
                        ...data
                    ])
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [wallet?._id])

    useEffect(() => {
        const itemsWithTotal = updateParentItems(billItems);
        const parentChildArray = buildHierarchy(itemsWithTotal);
        setHierarchyArr(parentChildArray);
        let locTaxExclAmount = 0;
        let locTotalTaxAmount = 0;
        let isAnyError = false;

        itemsWithTotal.map((item) => {
            if (item?.parent == null || item?.parent == undefined) {
                if (parseFloat(item?.numberOfChildren || 0) == 0) {
                    let locTaxs = item?.taxRelations || [];
                    let locTotalAmount = parseFloat(parseFloat(item?.qTy || 0) * parseFloat(item?.rate || 0)).toFixed(2);
                    let locLocTotalTaxAmount = 0;
                    locTaxs.map((tax) => {
                        if (tax?.type === "%") {
                            let num = parseFloat(locTotalAmount || 0) * (parseFloat(tax?.percent || 0) / 100)
                            locLocTotalTaxAmount = parseFloat(locLocTotalTaxAmount || 0) + num
                        } else {
                            locLocTotalTaxAmount = parseFloat(locLocTotalTaxAmount || 0) + parseFloat(tax?.amount || 0)
                        }
                    })
                    locTaxExclAmount = parseFloat(locTaxExclAmount || 0) + parseFloat(locTotalAmount || 0)
                    locTotalTaxAmount = parseFloat(locTotalTaxAmount || 0) + parseFloat(locLocTotalTaxAmount || 0)
                } else {
                    locTaxExclAmount = parseFloat(locTaxExclAmount || 0) + parseFloat(item?.totalRateAmount || 0)
                    locTotalTaxAmount = parseFloat(locTotalTaxAmount || 0) + parseFloat(item?.totalTaxAmount || 0)
                }
            }
            if (item?.isNotValid == true && isAnyError == false) {
                isAnyError = true;
            }
        })

        setBillListTotals({
            taxExclTotalAmount: (parseFloat(locTaxExclAmount || 0)).toFixed(2),
            taxInclTotalAmount: (parseFloat(locTaxExclAmount || 0) + parseFloat(locTotalTaxAmount || 0)).toFixed(2),
            totalTaxAmount: (parseFloat(locTotalTaxAmount || 0)).toFixed(2)
        })
        setBillListError(isAnyError)

    }, [billItems])

    //select unselect to delete multiple
    const unSelectHandle = (selectedIds) => {
        let arr = [...selectedIds]
        let flag = false;
        Object.keys(parentChildrenMap).forEach(key => {
            let parentId = key;
            let children = parentChildrenMap[key] ? parentChildrenMap[key] : [];
            let selected = children.filter((id) => selectedIds.includes(id))
            if (selectedIds.includes(parentId) && children.length > selected.length && children.length > 0 && selectedIds.includes(parentId)) {
                arr = arr.filter((id) => id != parentId)
                flag = true;
            }
        });
        if (flag) {
            arr = unSelectHandle(arr)
        }
        return arr
    }

    const handleCheck = (curId, isChecked) => {
        let arr = []
        let objMap = {}
        let allChildren = parentChildrenMap[curId] ? parentChildrenMap[curId] : []
        let allIds = [...allChildren, curId]
        if (isChecked) {
            const setArr = new Set([...allIds, ...allSelectedIds])
            arr = [...setArr]
        } else {
            arr = allSelectedIds.filter(item => !allIds.includes(item));
            arr = unSelectHandle(arr)
        }
        setAllSelectedIds(arr)
    }

    const createNewBillItem = async (isBillGroup, parentId = null, numberOfChildren, parentAncestor) => {
        setLoader(true)
        await addBillItemNew({
            isBillGroup,
            billListId: billListId,
            parentId,
            numberOfChildren,
            parentAncestor
        })
            .then((data) => {
                getInitialData();
            })
            .catch((err) => {
                console.log(err)
                setLoader(false)
            })
    }

    //it change numbers of workitem (not in work)
    const deleteWotkItems = async () => {
        let bulkUpdateObjArr = []
        let deletableOfferings = []
        Object.keys(parentChildrenMap).forEach(id => {
            if (!allSelectedIds.includes(id)) {
                const dirctChildren = idBillItemMap[id].children.map((item) => item?._id)
                const undeletableChildren = dirctChildren.filter((id) => !allSelectedIds.includes(id))
                if (dirctChildren.length !== undeletableChildren.length) {
                    bulkUpdateObjArr.push({
                        updateOne: {
                            filter: { _id: id },
                            update: {
                                numberOfChildren: undeletableChildren.length,
                                items: undeletableChildren,
                            }
                        }
                    })
                }
            }
        })

        allSelectedIds.map((id) => {
            const data = idBillItemMap[id]?.resources || []
            const offeringIds = data.map((item) => { return item?.resource?._id })
            deletableOfferings = [...deletableOfferings, ...offeringIds]
        })

        // await deleteBudgetWorkItem({
        //     bulkUpdateObjArr,
        //     deleteAbleIds: allSelectedIds,
        //     deletableOfferings
        // })
        //     .then(async (data) => {
        //         await getInitialData(false)
        //         setWorkitemDeleteOpen(false)
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })
    }

    return (<>
        <div className={classes.tableCont} >
            <div className={classes.overflowCont} >
                <table className={classes.tableSty} >
                    <thead>
                        <tr>
                            <th className={classes.width100} >Item Type<span className={classes.starSty} >*</span></th>
                            <th>
                                <div className={classes.titleBtnCont} >
                                    <p>Item Group / Bill Item<span className={classes.starSty} >*</span></p>
                                    {!needToMakeDisable && (
                                        <MyPopOver
                                            closeOnClick={true}
                                            appearContent={
                                                <Button className={classes.btnCont} >
                                                    <AddIcon style={{ marginRight: "5px", fontSize: "15px", color: "white" }} />
                                                    <ExpandMoreIcon style={{ fontSize: "15px", color: "white" }} />
                                                </Button>
                                            }
                                            showContent={<div className={classes.optionsCont} >
                                                <div
                                                    className={classes.singleOption}
                                                    onClick={() => {
                                                        createNewBillItem(true, null, 0, [])
                                                    }}
                                                >
                                                    <img src="https://mybuildcost.io/app/assets/icons/dropdown-icon/item-group-icon.svg" />
                                                    <p>Item Group</p>
                                                </div>
                                                <div
                                                    className={classes.singleOption}
                                                    onClick={() => {
                                                        createNewBillItem(false, null, 0, [])
                                                    }}
                                                >
                                                    <img src="https://mybuildcost.io/app/assets/icons/dropdown-icon/work-item-icon.svg" />
                                                    <p>Item Bill</p>
                                                </div>
                                            </div>}
                                        />
                                    )}
                                </div>
                            </th>
                            <th className={classes.width150} >Quantity<span className={classes.starSty} >*</span></th>
                            <th className={classes.width200} >Rate/Unit<span className={classes.starSty} >*</span></th>
                            <th className={classes.width200} >Unit<span className={classes.starSty} >*</span></th>
                            <th className={classes.width250} >Taxes</th>
                            <th className={classes.width150} >Amount(Tax Excl)</th>
                            <th className={classes.width150} >Tax</th>
                            <th className={classes.width200} >Amount(Tax Incl)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {hierarchyArr && hierarchyArr.map((billitemData) => (
                            <BillItemEdit
                                key={billitemData._id}
                                billItems={billItems}
                                setBillItems={setBillItems}
                                billitemData={billitemData}
                                createNewBillItem={createNewBillItem}
                                paddingLeft={15}
                                isCollapseChild={isCollapseChild}
                                needToMakeDisable={needToMakeDisable}
                                handleCheck={handleCheck}
                                taxs={taxs}
                                setTaxs={setTaxs}
                                projectProfileId={projectProfileId}
                                wallet={wallet}
                                allSelectedIds={allSelectedIds}
                            />
                        ))}
                    </tbody>
                    <thead>
                        <tr>
                            <th colspan="6" style={{ textAlign: "left", paddingLeft: "8px" }}  >Totals</th>
                            <th className={classes.width150} style={{ textAlign: "right", paddingRight: "2px" }} >{billListTotals?.taxExclTotalAmount}</th>
                            <th className={classes.width150} style={{ textAlign: "right", paddingRight: "2px" }} >{billListTotals?.totalTaxAmount}</th>
                            <th className={classes.width200} style={{ textAlign: "right", paddingRight: "2px" }} >{billListTotals?.taxInclTotalAmount}</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <div className={allSelectedIds && allSelectedIds.length > 0 ? classes.bottomBarForDeleteShow : classes.bottomBarForDeleteHide} >
            <div className={classes.selectText} >
                {`${allSelectedIds.length} Selected`}
            </div>
            <div className={classes.bottomRightSide} >
                <IconButton onClick={() => { setWorkitemDeleteOpen(true) }} >
                    <DeleteIcon />
                    <p className={classes.iconText} >Delete</p>
                </IconButton>

                <IconButton onClick={() => { setAllSelectedIds([]) }} >
                    <CloseIcon />
                    <p className={classes.iconText} >Cancel</p>
                </IconButton>
            </div>
        </div>
        <DeleteConfirmBox
            open={workitemDeleteOpen}
            setOpen={setWorkitemDeleteOpen}
            loading={loader}
            pageTitle={`Delete Workitem`}
            cantent={
                <div className={classes.deleteConfirm} >
                    <ReportProblemIcon />
                    <h3>Are you sure?</h3>
                    <p>You won't be able to revert this!</p>
                </div>
            }
            cancleConfirmDelete={() => {
                setWorkitemDeleteOpen(false)
            }}
            confirmDelete={() => {
                deleteWotkItems()
            }}
        />
    </>);
};

export default BillListEdit;